import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatFormField, matFormFieldAnimations } from '@angular/material/form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { PhoneNumber, getShortCountryCode } from '@remberg/global/common/core';
import { CountryCode } from '@remberg/global/ui';
import { GlobalSelectors, LayoutService, RootGlobalState } from '@remberg/ui-core/core';
import {
  BehaviorSubject,
  Subscription,
  firstValueFrom,
  from,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs';

@Component({
  selector: 'app-phone-number-input-deprecated',
  templateUrl: './phone-number-input-deprecated.component.html',
  styleUrls: ['./phone-number-input-deprecated.component.scss'],
  animations: [matFormFieldAnimations.transitionMessages],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberInputDeprecatedComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  private requiredValue = false;
  private disabledValue = false;
  public readonly numberControl = new UntypedFormControl();
  protected readonly animationState = 'enter';
  private subscription = new Subscription();
  protected readonly translations = {
    search: $localize`:@@search:Search`,
    noEntriesFound: $localize`:@@noEntriesFound:No Entries Found`,
  };

  /** Set input as required */
  public get required(): boolean {
    return this.requiredValue;
  }
  @Input() public set required(v: boolean | string) {
    this.requiredValue = typeof v === 'string' || v;
  }
  @Input() public isInErrorState = false;
  @Input() public inputErrorMessage?: string;
  @Input() public minLength?: number | null = 0;
  @Input() public maxLength?: number | null = null;
  @Input() public allowedSpecialCharacters?: string = '';
  /** Set input as disabled */
  public get disabled(): boolean {
    return this.disabledValue;
  }
  @Input()
  public set disabled(v: boolean | string) {
    this.disabledValue = typeof v === 'string' || v;
  }
  /** Custom Label for phone number */
  @Input() public label = $localize`:@@phoneNumber:Phone number`;

  private readonly countryCode$ = new BehaviorSubject<[string, string, string] | undefined>(
    undefined,
  );
  protected readonly countryCodeWithFallback$ = this.countryCode$.pipe(
    switchMap(this.determineCountryCodeWithFallback.bind(this)),
  );

  @Input() public fullWidth?: boolean;

  private readonly countryCodeOptions: [string, string, string][] = CountryCode;
  protected isParentDirty = false;
  protected filteredCountryCodeOptions: [string, string, string][] = CountryCode;
  protected phoneNumberRegex = '';

  @ViewChild('countryCodeSelect') protected countryCodeSelect?: MatSelect;
  @ViewChild(MatFormField) protected formField?: MatFormField;

  constructor(
    public readonly layout: LayoutService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly store: Store<RootGlobalState>,
    @Optional() @Self() private readonly ngControl?: NgControl,
  ) {
    if (this.ngControl) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    if (this.ngControl?.control?.hasValidator(Validators.required)) {
      // This is used to set input as required for reactive form.
      this.required = true;
    }
    if (this.allowedSpecialCharacters) {
      this.phoneNumberRegex = `[0-9 ${this.allowedSpecialCharacters}]*`;
    } else {
      this.phoneNumberRegex = '[0-9 ]*';
    }
    if (this.ngControl?.disabled) {
      this.disabled = true;
    }

    const parentControl = this.ngControl?.control?.parent;
    if (parentControl) {
      this.subscription.add(
        parentControl.statusChanges.subscribe(() => {
          this.isParentDirty = parentControl.dirty;
          this.cdRef.markForCheck();
        }),
      );
    }
    const initialValue = this.ngControl?.value;
    if (initialValue?.countryPrefix) {
      const country = this.countryCodeOptions.find(
        (country) => country[2] === initialValue.countryPrefix,
      );
      if (country?.length === 3) {
        this.countryCode$.next([country[0], country[1], country[2]]);
        this.cdRef.markForCheck();
      }
    }
  }

  public ngOnDestroy(): void {
    this.countryCode$.complete();
    this.subscription.unsubscribe();
  }

  public get invalid(): boolean {
    return this.numberControl ? this.numberControl.invalid : false;
  }

  public get showError(): boolean {
    if (!this.numberControl) {
      return false;
    }
    const { dirty, touched } = this.numberControl;
    return this.invalid ? this.isParentDirty || dirty || touched : false;
  }

  protected get errorMessage(): string | undefined {
    if (this.inputErrorMessage) {
      return this.inputErrorMessage;
    }
    if (this.numberControl.errors?.['required']) {
      return $localize`:@@phoneNumberIsRequired:Phone number is required`;
    } else if (this.numberControl.errors?.['minlength']) {
      return $localize`:@@inputTooShort:Input too short`;
    } else if (this.numberControl.errors?.['maxlength']) {
      return $localize`:@@inputTooLong:Input too long`;
    } else if (this.numberControl.errors?.['pattern']) {
      return $localize`:@@invalidPhoneNumber:Invalid phone number`;
    }
    return undefined;
  }

  protected compareWith(o1: typeof CountryCode, o2: typeof CountryCode): boolean {
    return o1?.[0] === o2?.[0] && o1?.[1] === o2?.[1] && o1?.[2] === o2?.[2];
  }

  protected countryCodeSelected($event: MatSelectChange): void {
    this.countryCode$.next($event.value);
    this.numberControl.setValue(this.numberControl?.value);
  }

  protected openCountryCodeSelect(): void {
    this.countryCodeSelect?.open();
  }

  protected filterCountryCodeOptions(searchString: string): void {
    this.filteredCountryCodeOptions = this.countryCodeOptions.filter(
      (value: [string, string, string]) =>
        value.join(' ').toLowerCase().includes(searchString.replace('+', '').toLowerCase()),
    );
  }

  public writeValue(phoneNumber: PhoneNumber): void {
    this.numberControl.reset(phoneNumber?.number, { emitEvent: false });
    if (phoneNumber?.countryPrefix) {
      const country = this.countryCodeOptions.find(
        (country) => country[2] === phoneNumber.countryPrefix,
      );
      if (country && country.length === 3) {
        this.countryCode$.next([country[0], country[1], country[2]]);
        this.cdRef.markForCheck();
      }
    }
  }

  public registerOnChange(onChangeFn: () => void): void {
    this.subscription.add(
      this.numberControl.valueChanges
        .pipe(
          withLatestFrom(this.countryCode$),
          switchMap(([value, countryCode]) =>
            from(this.determineCountryCodeWithFallback(countryCode)).pipe(
              map((countryCode) => ({
                countryCode,
                number: value,
              })),
            ),
          ),
          map(({ number, countryCode }) => ({
            number,
            countryPrefix: countryCode[2],
          })),
        )
        .subscribe(onChangeFn),
    );
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.numberControl.disable();
    } else {
      this.numberControl.enable();
    }
  }

  public registerOnTouched(onTouchedFn: () => void): void {
    this.subscription.add(this.numberControl.valueChanges.subscribe(onTouchedFn));
  }

  private async determineCountryCodeWithFallback(
    countryCode?: [string, string, string],
  ): Promise<[string, string, string]> {
    if (!countryCode) {
      const lang = (
        await firstValueFrom(this.store.select(GlobalSelectors.selectOrganizationCrmData))
      )?.lang;
      if (lang && this.countryCodeOptions?.length > 0) {
        const languageCode = getShortCountryCode(lang);
        if (languageCode) {
          const defaultCountryCode = this.countryCodeOptions.find(
            (x) => x[1].toLowerCase() === languageCode.toLowerCase(),
          );
          if (defaultCountryCode) {
            return defaultCountryCode;
          }
        }
      }
    }
    return countryCode ?? ['Germany', 'DE', '49'];
  }
}
