<button
  mat-button
  [matMenuTriggerFor]="menu"
  mat-raised-button
  class="color-swatch-picker-button"
  [style.--color-swatch-picker-height]="height"
  data-test-id="color-swatch-picker"
>
  <div
    class="selected-color"
    [ngStyle]="{ 'background-color': selectedColorPalette | colorOption: 'background' }"
  ></div>
  <mat-icon class="button-arrow">keyboard_arrow_down</mat-icon>
</button>

<mat-menu #menu="matMenu" class="color-swatch-picker-height">
  <div
    class="color-options-grid"
    [ngStyle]="{ 'grid-template-rows': 'repeat( ' + rowsPerOptions + ', 1fr)' }"
  >
    <a
      class="color-option"
      *ngFor="let color of colorPaletteOptions"
      [ngStyle]="{ 'background-color': color | colorOption: 'background' }"
      [attr.data-test-id]="'color-option-' + color"
      (click)="selectColor(color)"
    >
    </a>
  </div>
</mat-menu>
