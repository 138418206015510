import { getPlatforms } from '@ionic/angular';
import { createSelector } from '@ngrx/store';
import { ApplicationTypeEnum } from '@remberg/analytics/common/main';
import {
  ContactsViewTypeEnum,
  OrganizationsViewTypeEnum,
  contactRawToContactBasic,
  contactRawToContactCompatibility,
  organizationRawToOrganizationBasic,
  organizationRawToOrganizationCompatibility,
} from '@remberg/crm/common/main';
import {
  ADMIN_SUBDOMAIN,
  InternalFeatureFlagValues,
  LanguageCodeEnum,
  getFontColorDependingOnBackground,
  getIsoLanguageCodeFromLanguageCode,
  getShortCountryCode,
  isDefined,
} from '@remberg/global/common/core';
import {
  ConnectionStatusEnum,
  CountryCode,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_PAGESIZE,
  DEFAULT_TIME_PICKER_INTERVAL,
  DefaultColors,
} from '@remberg/global/ui';
import { PartsViewTypeEnum } from '@remberg/parts/common/main';
import { SettingsPermissionsEnum } from '@remberg/users/common/main';
import { SessionInfo } from '../../definitions';
import { FeatureFlagKey, PermissionAction } from '../../definitions/permission-checks';
import { checkFeatureFlag, checkUserPermission } from '../../helpers/permission-check-helpers';
import { selectGlobalState } from '../core-ui.definitions';
import { generateNavlinksForUser } from './global-navlink.helpers';

const selectIsGlobalInitializationComplete = createSelector(
  selectGlobalState,
  (state) => state.isGlobalInitializationComplete,
);

// Contact Selectors
const selectContactRaw = createSelector(selectGlobalState, (state) => state.contact);
const selectCurrentContactId = createSelector(selectContactRaw, (contact) => contact?._id);
const selectCurrentContact = createSelector(selectContactRaw, (contact) =>
  contactRawToContactBasic(contact),
);
const selectCurrentContactPictureId = createSelector(
  selectCurrentContact,
  (contact) => contact?.profilePictureId,
);

// RembergUser Selectors
const selectCurrentRembergUser = createSelector(selectGlobalState, (state) => state.rembergUser);
const selectIsTenantOwner = createSelector(
  selectCurrentRembergUser,
  (rembergUser) => !!rembergUser?.isTenantOwner,
);
const selectUserSettings = createSelector(
  selectCurrentRembergUser,
  (rembergUser) => rembergUser?.settings,
);
const selectUserLang = createSelector(
  selectCurrentRembergUser,
  (rembergUser) => rembergUser?.lang || LanguageCodeEnum.EN_US,
);
const selectUserLanguageIsoFormat = createSelector(selectUserLang, (language) =>
  getIsoLanguageCodeFromLanguageCode(language),
);
const selectIsLoggedIn = createSelector(selectGlobalState, (state) => !!state.rembergUser);
const selectIsRembergAdmin = createSelector(
  selectGlobalState,
  (state) => !!state.rembergUser?.isRembergAdmin,
);

// Tenant Selectors
const selectTenant = createSelector(selectGlobalState, (state) => state.tenant);
const selectTenantPublic = createSelector(selectGlobalState, (state) => state.tenantPublic);
const selectTenantId = createSelector(
  selectTenant,
  selectTenantPublic,
  (tenant, tenantPublic) => tenant?._id ?? tenantPublic?.tenantId,
);
const selectTenantConfiguration = createSelector(selectTenant, (tenant) => tenant?.configuration);
const selectIsSerialNumberUniquePerTenant = createSelector(
  selectTenantConfiguration,
  (tenantConfiguration) => !!tenantConfiguration?.isAssetSerialNumberUnique,
);

const selectTenantAllowedLangs = createSelector(
  selectTenantConfiguration,
  (tenantConfiguration) => tenantConfiguration?.allowedLangs,
);
const selectTenantAllowedLanguagesIsoFormat = createSelector(
  selectTenantAllowedLangs,
  (allowedLanguages) =>
    allowedLanguages?.filter(isDefined).map(getIsoLanguageCodeFromLanguageCode) ?? [],
);
const selectDefaultTimePickerInterval = createSelector(
  selectTenantConfiguration,
  (tenantConfiguration) =>
    tenantConfiguration?.defaultTimePickerIntervalInMin ?? DEFAULT_TIME_PICKER_INTERVAL,
);
const selectTenantFeatures = createSelector(
  selectTenant,
  selectTenantPublic,
  selectIsTenantOwner,
  (tenant, tenantPublic, isTenantOwner) =>
    (isTenantOwner
      ? (tenant?.internalFeatureFlags ?? tenantPublic?.internalFeatureFlags)
      : (tenant?.externalFeatureFlags ?? tenantPublic?.externalFeatureFlags)) as
      | InternalFeatureFlagValues
      | undefined,
);

const selectHasFeature = (featureFlag: FeatureFlagKey) =>
  createSelector(
    selectTenantFeatures,
    (features): boolean => !!features && checkFeatureFlag(featureFlag, features),
  );

const selectTheme = createSelector(
  selectTenant,
  selectTenantPublic,
  (tenant, tenantPublic) => tenant?.theme ?? tenantPublic?.theme,
);
const selectPrimaryThemeColor = createSelector(
  selectTheme,
  (theme) => theme?.primaryThemeColor ?? DefaultColors.PrimaryThemeColor,
);
const selectBackgroundThemeColor = createSelector(
  selectTheme,
  (theme) => theme?.backgroundThemeColor ?? DefaultColors.BackgroundThemeColor,
);
const selectTextColorDependingOnBackground = createSelector(selectPrimaryThemeColor, (color) =>
  getFontColorDependingOnBackground(color, 'hex'),
);
const selectLogoFileId = createSelector(selectTheme, (theme) => theme?.logoFileId);

// Organization Selectors
const selectOrganizationRaw = createSelector(selectGlobalState, (state) => state?.organization);
const selectOrganizationId = createSelector(
  selectOrganizationRaw,
  (organization) => organization?._id,
);
const selectOrganizationBasic = createSelector(selectOrganizationRaw, (organization) =>
  organizationRawToOrganizationBasic(organization),
);
const selectOrganizationCrmData = createSelector(
  selectOrganizationRaw,
  (organization) => organization?.crmData,
);

const selectOrganizationCrmCoordinates = createSelector(selectOrganizationCrmData, (crmData) => [
  crmData?.shippingAddress?.latitude,
  crmData?.shippingAddress?.longitude,
]);

const selectDefaultCountryCode = createSelector(
  selectOrganizationCrmData,
  (crmData): [string, string, string] => {
    if (crmData?.lang) {
      const languageCode = getShortCountryCode(crmData?.lang)?.toLowerCase();
      const defaultCountry = CountryCode.find((cc) => cc[1].toLowerCase() === languageCode);
      if (defaultCountry) {
        return defaultCountry;
      }
    }
    return DEFAULT_COUNTRY_CODE;
  },
);

const selectSessionInfo = createSelector(
  selectGlobalState,
  (state): SessionInfo => ({
    contact: contactRawToContactCompatibility(state.contact),
    rembergUser: state.rembergUser,
    organization: organizationRawToOrganizationCompatibility(state.organization),
    tenant: state.tenant,
    featureFlags: state.organization?.isTenantOwner
      ? state.tenant?.internalFeatureFlags
      : state.tenant?.externalFeatureFlags,
  }),
);

const selectHasToken = createSelector(selectGlobalState, (state) => !!state.token);
const selectToken = createSelector(selectGlobalState, (state) => state.token);

const selectHasUserChosenToLogout = createSelector(
  selectGlobalState,
  (state) => !!state.hasUserChosenToLogout,
);

const selectUserRole = createSelector(selectGlobalState, (state) => state.userRole);

const selectUserPermissions = createSelector(selectUserRole, (userRole) => userRole?.permissions);

const selectHasPermission = (action: PermissionAction, viewCheck?: boolean) =>
  createSelector(
    selectUserPermissions,
    selectTenantFeatures,
    (permissions, features): boolean =>
      !!permissions && !!features && checkUserPermission(action, permissions, features, viewCheck),
  );

const selectDisplayAssetStatus = createSelector(
  selectGlobalState,
  (state) => !!state.tenant?.internalFeatureFlags.assetsStatus,
);

const selectIsAssetsTemporaryEnabled = createSelector(
  selectTenantFeatures,
  (tenantFeatures) => !!tenantFeatures?.assetsTemporary,
);

const selectIsAssetTypesTemporaryEnabled = createSelector(
  selectTenantFeatures,
  (tenantFeatures) => !!tenantFeatures?.assetTypesTemporary,
);

const selectIsTicketsTemporaryEnabled = createSelector(
  selectTenantFeatures,
  (tenantFeatures) => !!tenantFeatures?.ticketsTemporary,
);

const selectIsWorkOrderTemporaryEnabled = createSelector(
  selectTenantFeatures,
  (tenantFeatures) => !!tenantFeatures?.workOrdersTemporary,
);

const selectIsNotesEnabled = createSelector(
  selectTenantFeatures,
  (tenantFeatures) => !!tenantFeatures?.notes,
);

const selectIsPortalEnabled = createSelector(
  selectTenantFeatures,
  (tenantFeatures) => !!tenantFeatures?.portal,
);

const selectIsPortalEnabledAndIsTenantOwner = createSelector(
  selectIsPortalEnabled,
  selectIsTenantOwner,
  (isPortalEnabled, isTenantOwner) => isPortalEnabled && isTenantOwner,
);

const selectFilesDownloadUrl = createSelector(selectGlobalState, (state) => state.filesDownloadUrl);

// Breakpoints
const selectBreakpoints = createSelector(selectGlobalState, (state) => state.breakpoints);
const selectIsXSmallView = createSelector(
  selectBreakpoints,
  (breakpoints) => !!breakpoints?.isXSmallView,
);
const selectIsSmallView = createSelector(
  selectBreakpoints,
  (breakpoints) => !!breakpoints?.isSmallView,
);
const selectIsXSmallOrSmallView = createSelector(
  selectBreakpoints,
  (breakpoints) => !!breakpoints?.isXSmallOrSmallView,
);
const selectIsMediumOrLargeOrXLargeView = createSelector(
  selectBreakpoints,
  (breakpoints) => !!breakpoints?.isMediumOrLargeOrXLargeView,
);
const selectIsMediumView = createSelector(
  selectBreakpoints,
  (breakpoints) => !!breakpoints?.isMediumView,
);
const selectIsLargeView = createSelector(
  selectBreakpoints,
  (breakpoints) => !!breakpoints?.isLargeView,
);
const selectIsXLargeView = createSelector(
  selectBreakpoints,
  (breakpoints) => !!breakpoints?.isXLargeView,
);
const selectIsBreakpointsInitialized = createSelector(
  selectBreakpoints,
  (breakpoints) => !!breakpoints,
);

const selectDeviceType = createSelector(selectGlobalState, (state) => state.deviceType);

const selectVersionInfo = createSelector(selectGlobalState, (state) => state.versionInfo);
const selectShortVersionInfoString = createSelector(
  selectVersionInfo,
  (versionInfo) => versionInfo?.versionInfoString ?? 'Unknown Version',
);
const selectExtendedVersionInfoString = createSelector(selectVersionInfo, (versionInfo) =>
  versionInfo
    ? `${versionInfo?.versionInfoString} ${versionInfo?.additionalVersionInfo}`
    : 'Unknown Version',
);

/**
 * @deprecated use getDeviceType and/or getBreakpoints instead
 */
const selectLayout = createSelector(
  selectBreakpoints,
  selectDeviceType,
  (breakpoints, deviceType) => ({
    ...(deviceType ? deviceType : {}),
    ...(breakpoints ? breakpoints : {}),
  }),
);

// Mobile App Selectors

const selectIsIonic = createSelector(selectDeviceType, (layout) => !!layout?.isIonic);

const selectIsPhoneDevice = createSelector(selectDeviceType, (layout) => !!layout?.isPhoneDevice);

const selectIsTabletDevice = createSelector(selectDeviceType, (layout) => !!layout?.isTabletDevice);

const selectIsAndroidOrIos = createSelector(
  selectDeviceType,
  (layout) => layout?.isAndroid || layout?.isIos,
);

const selectSyncState = createSelector(selectGlobalState, (state) => state?.syncState);

const selectIsOnline = createSelector(
  selectGlobalState,
  (state) => state?.connectionStatus !== ConnectionStatusEnum.Offline,
);

const selectGlobalLoadingIndicatorIsSpinning = createSelector(
  selectGlobalState,
  (state) => !!Object.keys(state?.loadingIds).length,
);

// ApplicationDomainState Selectors
const selectApplicationDomainState = createSelector(
  selectGlobalState,
  (state) => state?.applicationDomain,
);
const selectTenantSubdomain = createSelector(
  selectApplicationDomainState,
  (applicationDomain) => applicationDomain?.tenantSubdomain,
);
const selectApplicationRootDomain = createSelector(
  selectApplicationDomainState,
  (applicationDomain) => applicationDomain?.applicationRootDomain,
);
const selectIsAtTenantSubdomain = createSelector(
  selectTenantSubdomain,
  (tenantSubdomain) => !!tenantSubdomain && tenantSubdomain !== ADMIN_SUBDOMAIN,
);
const selectIsAtAdminSubdomain = createSelector(
  selectTenantSubdomain,
  (tenantSubdomain) => tenantSubdomain === ADMIN_SUBDOMAIN,
);
const selectIsAtPreviewDomain = createSelector(
  selectApplicationRootDomain,
  (domain) => !!domain?.startsWith('preview-') || !!domain?.startsWith('pr-'),
);
const selectIsSubdomainRoutingActive = createSelector(
  selectIsIonic,
  selectIsAtPreviewDomain,
  (isIonic, isAtPreviewDomain) => !(isIonic || isAtPreviewDomain),
);

// Request Headers Selectors
const selectPublicOutgoingRequestHeaders = createSelector(
  selectIsIonic,
  selectDeviceType,
  selectShortVersionInfoString,
  (isIonic, deviceType, versionInfoString) => ({
    'Remberg-Application-Type': isIonic ? ApplicationTypeEnum.MOBILE : ApplicationTypeEnum.WEBAPP,
    'Remberg-Device-Type': JSON.stringify(getPlatforms()),
    ...(versionInfoString ? { 'Remberg-Version': versionInfoString } : {}),
    ...(deviceType?.browserName ? { 'Remberg-Browser-Name': deviceType.browserName } : {}),
    ...(deviceType?.deviceId ? { 'Device-Id': deviceType.deviceId } : {}),
  }),
);
const selectOutgoingRequestHeaders = createSelector(
  selectPublicOutgoingRequestHeaders,
  selectToken,
  (publicHeaders, token) => ({
    ...publicHeaders,
    Authorization: `Bearer ${token}`,
  }),
);
const selectOutgoingRequestHeadersBypassSW = createSelector(
  selectOutgoingRequestHeaders,
  (headers) => ({
    ...headers,
    'ngsw-bypass': 'true',
  }),
);

// User Settings Selectors

const selectDefaultPartsViewType = createSelector(
  selectUserSettings,
  (settings) => settings?.partsSettings?.defaultViewType ?? PartsViewTypeEnum.ALL,
);

const selectDefaultOrganizationsViewType = createSelector(
  selectUserSettings,
  (userSettings) =>
    userSettings?.organizationsSettings?.defaultViewType ?? OrganizationsViewTypeEnum.ALL,
);

const selectDefaultContactsViewType = createSelector(
  selectUserSettings,
  (settings) => settings?.contactsSettings?.defaultViewType ?? ContactsViewTypeEnum.ALL,
);

const selectDefaultAssetPageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.assetSettings?.defaultPageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultAssetTypePageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.assetSettings?.defaultAssetTypePageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultCasePageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.caseSettings?.defaultPageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultWorkOrderPageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.workOrderSettings?.defaultPageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultMaintenancePlanPageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.workOrderSettings?.defaultMaintenancePlanPageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultFormPageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.forms?.defaultPageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultTasksPageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.tasksSettings?.defaultPageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultPartsPageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.partsSettings?.defaultPageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultOrganizationsPageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.organizationsSettings?.defaultPageSize ?? DEFAULT_PAGESIZE,
);

const selectDefaultContactsPageSize = createSelector(
  selectUserSettings,
  (settings) => settings?.contactsSettings?.defaultPageSize ?? DEFAULT_PAGESIZE,
);

const selectSignature = createSelector(selectUserSettings, (settings) => settings?.signature);

const selectDefaultSupportEmail = createSelector(
  selectUserSettings,
  (settings) => settings?.defaultSupportEmail,
);

// Application & Permission Update Selectors

const selectIsApplicationUpdateAvailable = createSelector(
  selectGlobalState,
  (state) => !!state.isApplicationUpdateAvailable,
);

const selectUpdatedTenant = createSelector(selectGlobalState, (state) => state.updatedTenant);

const selectUpdatedUserRole = createSelector(selectGlobalState, (state) => state.updatedUserRole);

const selectLocalUIState = createSelector(selectGlobalState, (state) => state.localUIState);
const selectIsPrimaryNavigationOpen = createSelector(
  selectLocalUIState,
  (uiState) => !!uiState?.isPrimaryNavigationOpen,
);
const selectLocalUIStateFilesView = createSelector(
  selectLocalUIState,
  (uiState) => uiState?.filesView,
);
const selectIsServiceCaseSidebarOpen = createSelector(
  selectLocalUIState,
  (uiState) => uiState?.isServiceCaseSidebarOpen,
);

const selectIsApplicationAndStorageUpdateInProgress = createSelector(
  selectGlobalState,
  (state) => state.isApplicationAndStorageUpdateInProgress,
);

const selectIsIonicAppBelowMinimumRequiredVersion = createSelector(
  selectGlobalState,
  (state) => !!state.isIonicAppBelowMinimumRequiredVersion,
);

// Intercom selectors

const selectIsIntercomWebInitialized = createSelector(
  selectGlobalState,
  (state) => !!state.isIntercomWebInitialized,
);
const selectIsIntercomMobileInitialized = createSelector(
  selectGlobalState,
  (state) => !!state.isIntercomMobileInitialized,
);
const selectIsIntercomInitialized = createSelector(
  selectIsIntercomWebInitialized,
  selectIsIntercomMobileInitialized,
  (isIntercomWebInitialized, isIntercomMobileInitialized) =>
    isIntercomWebInitialized || isIntercomMobileInitialized,
);

// Misc selectors

const selectBreadcrumbs = createSelector(selectGlobalState, (state) => state.breadcrumbs ?? []);

const selectOrganizationDetailPageLink = (organizationId: string | undefined) =>
  createSelector(
    selectUserPermissions,
    selectIsTenantOwner,
    selectIsIonic,
    (permissions, isTenantOwner, isIonic) => {
      if (organizationId && isTenantOwner && permissions?.organizations.viewExternal && !isIonic) {
        return `/organizations/detail/${organizationId}`;
      }
      return undefined;
    },
  );

const selectHasMessageTemplateEditionPermission = createSelector(
  selectUserPermissions,
  selectTenantFeatures,
  (permissions, features) =>
    !!features &&
    !!permissions &&
    checkUserPermission(SettingsPermissionsEnum.SETTINGS_MANAGE_CASES, permissions, features),
);

const selectIsAssetStatusColumnHidden = createSelector(
  selectGlobalState,
  (state) => !state.displayAssetStatus,
);

const selectNavLinks = createSelector(
  selectCurrentRembergUser,
  selectTenantFeatures,
  selectUserPermissions,
  selectIsIonic,
  (rembergUser, features, permissions, isIonic) =>
    generateNavlinksForUser(rembergUser, features, permissions, isIonic),
);

const selectIsFullscreen = createSelector(selectGlobalState, (state) => state.isFullscreen);

export const GlobalSelectors = {
  selectIsGlobalInitializationComplete,

  // Contact Selectors
  selectContactRaw,
  selectCurrentContactId,
  selectCurrentContact,
  selectCurrentContactPictureId,

  // RembergUser Selectors
  selectCurrentRembergUser,
  selectIsTenantOwner,
  selectUserSettings,
  selectUserLang,
  selectUserLanguageIsoFormat,
  selectIsLoggedIn,
  selectIsRembergAdmin,

  // Tenant Selectors
  selectTenant,
  selectTenantPublic,
  selectTenantId,
  selectTenantAllowedLangs,
  selectTenantAllowedLanguagesIsoFormat,
  selectDefaultTimePickerInterval,
  selectTenantFeatures,
  selectHasFeature,
  selectTenantConfiguration,
  selectIsSerialNumberUniquePerTenant,
  selectTheme,
  selectBackgroundThemeColor,
  selectTextColorDependingOnBackground,
  selectLogoFileId,

  // Organization Selectors
  selectOrganizationRaw,
  selectOrganizationId,
  selectOrganizationBasic,
  selectOrganizationCrmData,
  selectOrganizationCrmCoordinates,
  selectDefaultCountryCode,
  selectSessionInfo,
  selectHasToken,
  selectToken,
  selectHasUserChosenToLogout,
  selectUserRole,
  selectUserPermissions,
  selectHasPermission,
  selectDisplayAssetStatus,
  selectIsAssetsTemporaryEnabled,
  selectIsTicketsTemporaryEnabled,
  selectIsAssetTypesTemporaryEnabled,
  selectIsWorkOrderTemporaryEnabled,
  selectIsNotesEnabled,
  selectIsPortalEnabled,
  selectIsPortalEnabledAndIsTenantOwner,
  selectFilesDownloadUrl,

  // Breakpoints
  selectBreakpoints,
  selectIsXSmallView,
  selectIsSmallView,
  selectIsXSmallOrSmallView,
  selectIsMediumOrLargeOrXLargeView,
  selectIsMediumView,
  selectIsLargeView,
  selectIsXLargeView,
  selectIsBreakpointsInitialized,
  selectDeviceType,
  selectVersionInfo,
  selectShortVersionInfoString,
  selectExtendedVersionInfoString,
  selectLayout,

  // Mobile App Selectors
  selectIsIonic,
  selectIsPhoneDevice,
  selectIsTabletDevice,
  selectIsAndroidOrIos,
  selectSyncState,
  selectIsOnline,
  selectGlobalLoadingIndicatorIsSpinning,

  // ApplicationDomainState Selectors
  selectApplicationDomainState,
  selectTenantSubdomain,
  selectApplicationRootDomain,
  selectIsAtTenantSubdomain,
  selectIsAtAdminSubdomain,
  selectIsAtPreviewDomain,
  selectIsSubdomainRoutingActive,

  // Request Headers Selectors
  selectPublicOutgoingRequestHeaders,
  selectOutgoingRequestHeaders,
  selectOutgoingRequestHeadersBypassSW,

  // User Settings Selectors
  selectDefaultPartsViewType,
  selectDefaultOrganizationsViewType,
  selectDefaultContactsViewType,
  selectDefaultAssetPageSize,
  selectDefaultAssetTypePageSize,
  selectDefaultCasePageSize,
  selectDefaultWorkOrderPageSize,
  selectDefaultMaintenancePlanPageSize,
  selectDefaultFormPageSize,
  selectDefaultTasksPageSize, // Wojciech: is unused
  selectDefaultPartsPageSize,
  selectDefaultOrganizationsPageSize,
  selectDefaultContactsPageSize,
  selectSignature,
  selectDefaultSupportEmail,

  // Application & Permission Update Selectors
  selectIsApplicationUpdateAvailable,
  selectUpdatedTenant,
  selectUpdatedUserRole,
  selectLocalUIState,
  selectIsPrimaryNavigationOpen,
  selectLocalUIStateFilesView,
  selectIsServiceCaseSidebarOpen,
  selectIsApplicationAndStorageUpdateInProgress,
  selectIsIonicAppBelowMinimumRequiredVersion,

  // Intercom selectors
  selectIsIntercomWebInitialized,
  selectIsIntercomMobileInitialized,
  selectIsIntercomInitialized,

  // Misc selectors
  selectBreadcrumbs,
  selectOrganizationDetailPageLink,
  selectHasMessageTemplateEditionPermission,
  selectIsAssetStatusColumnHidden,
  selectNavLinks,
  selectIsFullscreen,
};
