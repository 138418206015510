export enum AdvancedFilterTypeEnum {
  USER_GROUP = 'userGroup',
  USER_ROLE = 'userRole',
  ASSET = 'asset',
  ASSET_TYPE = 'assetType',
  TENANT = 'tenant',
  ORGANIZATION = 'organization',
  CONTACT = 'contact',
  FORM_TEMPLATE = 'formTemplate',
  WORK_ORDER = 'workOrder',
  WORK_ORDER_2 = 'workOrder2',
  MAINTENANCE_PLAN = 'maintenancePlan',
  APPOINTMENT_TYPE = 'appointmentType',
  TICKET_2 = 'ticket2',
  /**
   * @deprecated Only used for the old work orders.
   */
  WORK_ORDER_TYPE = 'workOrderType',
  /**
   * @deprecated Only used for the old work orders.
   */
  WORK_ORDER_STATUS = 'workOrderStatus',
  WORK_ORDER_STATUS_2 = 'workOrderStatus2',
  WORK_ORDER_TYPE_2 = 'workOrderType2',
  /**
   * @deprecated Only used for the old tickets. Remove with tickets2
   */
  CASE = 'case',
  CASE_CATEGORY = 'caseCategory',
  /**
   * @deprecated Only used for the old tickets. Remove with tickets2
   */
  QUOTATION_REQUEST = 'quotationRequest',
  QUOTATION_REQUEST_EXTERNAL_ID = 'quotationRequestExternalId',
  /**
   * @deprecated Only used for the old tickets. Remove with tickets2
   */
  SUMMARY = 'summary',
  CITY = 'city',
  COUNTRY = 'country',
  PROVINCE = 'province',
  ENUM = 'enum',
  STATIC_SELECT = 'staticSelect',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  TEXT_EXISTS = 'textExists',
  BOUNDING_BOX = 'boundingBox',
  DATE = 'date',
  /**
   * Only deals with RembergDate, does not include times. Not to be confused with RembergDateTime values.
   */
  REMBERG_DATE = 'rembergDate',
  /**
   * Should only be used against a model field with the following modifiers applied:
   * ```
   * @Transform(transformToRembergDateTime, { toClassOnly: true })
   * @Prop({ type: RembergDateTimeSchemaType, required: false })
   * startDate?: RembergDateTime;
   * ```
   */
  REMBERG_DATE_TIME = 'rembergDateTime',
  REMBERG_TIMESTAMP = 'rembergTimestamp',
  WORKORDER_CITY = 'workOrderCity',
  WORKORDER_COUNTRY = 'workOrderCountry',
  WORKORDER_PROVINCE = 'workOrderProvince',
  NUMBER = 'number',
  SUPPORT_EMAIL = 'supportEmail',
  PART = 'part',
  /**
   * @deprecated fixing this typo requires a migration. collaboration team please take care of it.
   */
  CASE_CASTEGORY = 'caseCastegory',
}
