import { ComponentFactory, Type } from '@angular/core';
import { DialogPosition, MatDialogConfig } from '@angular/material/dialog';
import { DynamicButtonConfig, FilterType2 } from '@remberg/global/ui';
import { FilterField } from '../helpers/filters';
import { ModalDialogWrapper } from './modalDialogWrapper';

export interface DialogOptions<T> {
  childComponent: Type<T>;

  // dialog options
  dialogData: DialogData<T>;
  dialogAutoFocus?: boolean; // default to false
  dialogRestoreAutoFocus?: boolean; // default to false
  dialogBackdropCloseDisabled?: boolean; // default to false
  dialogBackdropClass?: string | string[];
  dialogHasBackdrop?: boolean;
  dialogCssClass?: string[];

  // modal options
  modalKeyboardClose?: boolean;
  modalBackdropDismiss?: boolean;
  modalCanDismiss?: boolean;
  modalCssClass?: string[] | undefined;
  modalShowBackdrop?: boolean;

  isModal?: boolean;
  isDialog?: boolean;
  dialogModalRef?: ModalDialogWrapper;
}

export interface DialogWrapperInput {
  // Modal Header Input Options
  headerShow?: boolean;
  headerShowWithoutMargin?: boolean;
  removeBottomMargin?: boolean;
  overflowXHidden?: boolean;
  overflowYHidden?: boolean;
  headerTitle?: string;
  headerCloseActionShow?: boolean;
  headerConfirmActionShow?: boolean;
  headerThreeDotActionShow?: boolean;

  // Style Input Options
  styleFullscreen?: boolean;
  styleNoMargin?: boolean;
  styleStickyHeader?: boolean;
  styleHeight?: string;
  styleWidth?: string;
  styleMaxWidth?: string;
  styleMaxHeight?: string;
  styleMinWidth?: string;
  styleMinHeight?: string;
  styleZIndex?: string;
  styleHeaderDivider?: boolean;

  // dialog wrapper confirmation logic
  showButtons?: boolean;
  hideAbortButton?: boolean;
  buttonsDirection?: 'vertical' | 'horizontal';
  buttons?: DynamicButtonConfig[];
}

export interface DialogData<T> {
  factory?: ComponentFactory<T>;
  // TODO: We should really find a way to type this.
  factoryInput: any[];
  wrapperInput: DialogWrapperInput;
  dialogModalRef?: ModalDialogWrapper;
  isModal?: boolean;
  isDialog?: boolean;
  materialConfig?: MatDialogConfig;
}

export interface DialogResultData {
  confirmed?: boolean;
  data?: any;
  componentInstance?: any;
}

function getDialogPositionNextToTarget(targetElement: Element): DialogPosition {
  const targetDOMRect = targetElement.getBoundingClientRect();
  const targetTop = targetDOMRect.top;
  const targetRight = targetDOMRect.right;
  const targetHeight = targetDOMRect.height;

  return {
    // Dialog will be displayed 16px bellow the target element
    top: `${targetTop + targetHeight + 16}px`,
    // It will have the same right margin as target element. We use window.innerWidth instead of calc(100vh-filterButtonRight) to work
    // correctly on resizing the window - to keep the same margin and not recalculating it.
    right: `${window.innerWidth - targetRight}px`,
  };
}

/**
 * Specialized method for opening the specified dialog relative to the desired position
 * indicated via the target param
 *
 * @param data Any specified dialog data to provide to the relevant dialog
 * @param target The target element used as the anchor for the dialog positioning.
 * The target element is inferred to be at the lowest selector level. This is relevant
 * for cases where multiple dialogs are overlapping eachother and we wish to target
 * the one that has implicit focus. This can also be a gotcha since it will always
 * select the lowest level descendant which may not be the desired behavior with
 * multiple non-unique data-targets
 * @param panelClass Provided panel classes for styling
 */
export function getDialogConfigNextToTarget<T>(
  data: T,
  target: string,
  panelClass: string[] = [],
): MatDialogConfig {
  const targetElementList = document.querySelectorAll(`[data-target="${target}"]`);
  const targetElement = targetElementList[targetElementList.length - 1];
  const dialogConfig = new MatDialogConfig<T>();
  if (targetElementList) {
    dialogConfig.position = getDialogPositionNextToTarget(targetElement);
  }
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = false;
  dialogConfig.data = data;
  dialogConfig.panelClass = [...panelClass, 'positioned-dialog'];

  return dialogConfig;
}

export function getMobileDialogConfig<T>(data: T, panelClass: string[] = []): MatDialogConfig {
  const dialogConfig = new MatDialogConfig<T>();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = false;
  dialogConfig.data = data;
  dialogConfig.panelClass = [...panelClass, 'mobile-device-full-screen'];
  return dialogConfig;
}

export interface FilteringDialogData<E extends string> {
  filterFields: FilterType2<E>[];
  filterFieldOptions: FilterField<E>;
}
