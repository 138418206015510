import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UnknownOr, getNumberCount, mapDetailsMapToUnknownOr } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  LogService,
  filterEmptyProps,
  mapPopulateToOfflinePopulate,
} from '@remberg/global/ui';
import {
  Part,
  PartsCreateOneBody,
  PartsFindManyByIdsBody,
  PartsFindManyBySerialNumbersBody,
  PartsFindManyQuery,
  PartsFindManyResponse,
  PartsFindOneQuery,
  PartsManageRelationshipsBody,
  PartsUpdateOneBody,
} from '@remberg/parts/common/main';
import { Observable, from, map } from 'rxjs';
import { PartsRequestParams } from './definitions';
import {
  PARTS_OFFLINE_SERVICE,
  PartsOfflineServiceInterface,
} from './definitions/parts.offline.service.interface';

@Injectable({
  providedIn: 'root',
})
export class PartsService {
  private readonly PARTS_URL = `${API_URL_PLACEHOLDER}/parts/v1`;

  constructor(
    private readonly httpService: HttpClient,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    private readonly logger: LogService,
    @Inject(PARTS_OFFLINE_SERVICE)
    private readonly partsOfflineService: PartsOfflineServiceInterface,
  ) {}

  public findMany(params: PartsRequestParams): Observable<PartsFindManyResponse> {
    if (!this.connectivityService.getConnected()) {
      this.logger.debug()(
        `[findMany]: Getting parts in offline mode with params ${JSON.stringify(params)}`,
      );
      const {
        pageIndex: offset,
        pageSize: limit,
        sorting,
        searchQuery: searchValue,
        filterQuery,
        staticFilters,
        populate,
      } = params;
      const offlinePopulateMap = mapPopulateToOfflinePopulate(populate);
      return from(
        this.partsOfflineService.getPartsWithCount({
          limit,
          offset,
          sortColumn: sorting?.field,
          sortDirection: sorting?.direction,
          searchValue,
          filterQuery,
          staticFilters,
          populate: offlinePopulateMap,
        }),
      ).pipe(map(this.mapPartsApiResponseToFindManyResponse));
    }

    this.logger.debug()(`[findMany]: Getting parts in online mode with params ${params}`);
    const findManyQueryParams = this.mapPartParamsToPartsFindManyQuery(params);
    const { filterObject, staticFilters, populate, ...partsParams } = findManyQueryParams;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(partsParams),
        ...(filterObject && { filterObject: JSON.stringify(filterObject) }),
        ...(staticFilters && { staticFilters: JSON.stringify(staticFilters) }),
        ...(populate && { populate: JSON.stringify(populate) }),
      },
    });

    return this.httpService.get<PartsFindManyResponse>(`${this.PARTS_URL}`, {
      params: httpParams,
    });
  }

  public findManyByIds(body: PartsFindManyByIdsBody): Observable<Part[]> {
    return this.httpService.put<Part[]>(`${this.PARTS_URL}`, body);
  }

  public findManyByIdsOrUnknown(body: PartsFindManyByIdsBody): Observable<UnknownOr<Part>[]> {
    return this.findManyByIds(body).pipe(
      map((parts) =>
        parts.reduce<Record<string, Part>>((acc, part) => ({ ...acc, [part._id]: part }), {}),
      ),
      map((partsMap) => mapDetailsMapToUnknownOr(partsMap, body.partIds)),
    );
  }

  public findOne(id: string, query?: PartsFindOneQuery): Observable<Part> {
    let httpParams = new HttpParams();
    if (query?.populate) {
      httpParams = httpParams.append('populate', JSON.stringify(query.populate));
    }

    return this.httpService.get<Part>(`${this.PARTS_URL}/${id}`, { params: httpParams });
  }

  public createOne(part: PartsCreateOneBody): Observable<Part> {
    return this.httpService.post<Part>(this.PARTS_URL, part);
  }

  public updateOne(id: string, part: PartsUpdateOneBody): Observable<Part> {
    return this.httpService.patch<Part>(`${this.PARTS_URL}/${id}`, part);
  }

  public findManyBySerialNumbers(
    partsFindManyBySerialNumbersBody: PartsFindManyBySerialNumbersBody,
  ): Observable<Part[]> {
    return this.httpService.put<Part[]>(
      `${this.PARTS_URL}/serialnumbers`,
      partsFindManyBySerialNumbersBody,
    );
  }

  public manageRelationships(relationships: PartsManageRelationshipsBody): Observable<Part> {
    return this.httpService.patch<Part>(`${this.PARTS_URL}/relationships`, relationships);
  }

  public deleteOne(id: string): Observable<void> {
    return this.httpService.delete<void>(`${this.PARTS_URL}/${id}`);
  }

  public updateImage(partId: string, image: File): Observable<Part> {
    const formData = new FormData();
    formData.append('image', image, encodeURIComponent(image.name));
    return this.httpService.patch<Part>(`${this.PARTS_URL}/${partId}/image`, formData);
  }

  private mapPartsApiResponseToFindManyResponse(
    partsApiResponse: ApiResponse<Part[]>,
  ): PartsFindManyResponse {
    return {
      parts: partsApiResponse.data,
      count: getNumberCount(partsApiResponse.count ?? 0),
    };
  }

  private mapPartParamsToPartsFindManyQuery(params: PartsRequestParams): PartsFindManyQuery {
    const { pageSize, pageIndex, staticFilters, searchQuery, filterQuery, sorting, populate } =
      params;
    return {
      limit: pageSize as number,
      page: pageIndex as number,
      ...(searchQuery && { search: searchQuery }),
      ...(filterQuery && filterQuery.filters.length > 0 && { filterObject: filterQuery }),
      ...(staticFilters && { staticFilters: staticFilters }),
      ...(sorting?.direction && { sortDirection: sorting.direction }),
      ...(sorting?.field && { sortField: sorting.field }),
      ...(populate && { populate }),
    };
  }
}
