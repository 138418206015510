import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAXIMUM_DB_COUNT_LIMIT } from '@remberg/global/common/core';

// Needed for i18n of labels
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  override itemsPerPageLabel = $localize`:@@elementsPerPage:Elements per page`;
  override nextPageLabel = $localize`:@@next:Next`;
  override previousPageLabel = $localize`:@@previous:Previous`;

  override getRangeLabel = function (page: number, pageSize: number, length: number): string {
    // console.log('Custom getRangeLabel executed...');
    const ofTranslated = $localize`:@@of:of`;
    if (length === 0 || pageSize === 0) {
      return '0 ' + ofTranslated + ' 0';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    let lengthText = length.toString();
    // console.log(lengthText);
    if (length >= MAXIMUM_DB_COUNT_LIMIT) {
      lengthText = $localize`:@@many:Many`;
    }
    return startIndex + 1 + ' - ' + endIndex + ' ' + ofTranslated + ' ' + lengthText;
  };
}
