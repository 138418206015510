import { MultiLanguageText } from '@remberg/global/common/core';
import { MultiLanguageHtml } from '../../core';
import { FormSectionTypesEnum } from './section-type.enum';

/**
 * Email section options, used in the config and the state.
 * Used in validation as well.
 */
export interface FormEmailSection {
  id: string;
  type: FormSectionTypesEnum.EMAIL_SECTION;
  config: FormEmailSectionConfig;
}

export interface FormEmailSectionConfig {
  drive?: FormEmailDriveConfig;
  headlineText: MultiLanguageText;
  helpTextHtmlAbove?: MultiLanguageHtml;
  helpTextHtmlBelow?: MultiLanguageHtml;
  requiredAmountOfOutgoingMails?: number;
  hidePreviewPdfButton?: boolean;
  hideDownloadPdfButton?: boolean;
  emailDialogPrefill?: FormEmailPrefillConfig;
  whitelistedDomains?: string[];
}

export interface FormEmailDriveConfig {
  folderId: string;
  uploadAttachments?: boolean;
}

export interface FormEmailPrefillConfig {
  to?: FormEmailPrefillRecipient;
  cc?: FormEmailPrefillRecipient;
  bcc?: FormEmailPrefillRecipient;

  subject?: MultiLanguageText;
  body?: MultiLanguageHtml;
  link?: {
    enable: boolean;
    textBefore?: MultiLanguageText;
  };
}

export interface FormEmailPrefillRecipient {
  // Dynamic prefill
  currentUser?: boolean;
  assignee?: boolean;
  creator?: boolean;
  fieldIds?: string[];
  workOrder?: {
    contacts?: boolean;
  };

  // Static prefill
  staticEmails?: string[];
}

export interface FormEmailSectionData {
  emailList: EmailData[];

  // PDF Attachment name, as provided by the user
  pdfAttachmentName?: string;

  // Files attached to each email
  pdfFile?: AttachmentInfo;
  additionalFiles?: AttachmentInfo[];
}

export interface EmailData {
  id: string;
  subject?: string;
  to?: string[];
  cc?: string[];
  bcc?: string[];

  // TODO: Replace these flags with one status field
  isDraft: boolean;
  isSent: boolean;
  isMarkedForSending?: boolean;

  // Used solely to prevent concurrency
  isProcessing?: boolean;

  content?: string;
  signature?: string;
}

export interface AttachmentInfo {
  _id: string;
  fileName: string;
  size: number;
  type?: string;
}

/**
 * 24MB is used for limiting the cumulative size of form email attachments.
 * NOTE: Used 24MB instead of 25MB (email attachment limit also used at other places)
 * as this does not take into account the pdf file size.
 */
export const MAX_FORM_EMAIL_ATTACHMENTS_SIZE = 24 * 1024 * 1024;
