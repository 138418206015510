import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdvancedFilter, AdvancedFilterOperatorEnum } from '@remberg/advanced-filters/common/main';
import { Asset, AssetsFilterEnum, AssetsFindManyResponse } from '@remberg/assets/common/main';
import {
  AssetOfflinePopulates,
  AssetRawOffline,
  AssetsFindManyOfflineQueryInterface,
  AssetsNewOfflineServiceInterface,
  AssetsOfflineAdvancedFilterColumns,
  AssetsOfflineAdvancedFilterConfigKeys,
  mapOneAssetRawOfflineToAsset,
} from '@remberg/assets/ui/clients';
import { getNumberCount } from '@remberg/global/common/core';
import {
  LogService,
  OfflinePopulateType,
  SQLQueryParams,
  SyncDataTypesEnum,
  assertDefined,
} from '@remberg/global/ui';
import {
  SQLConcatOperator,
  concatSQLFiltersByOperator,
  generateBooleanSQLFilterItemValue,
  generateContainsSQLFilter,
  generateDoesNotContainSQLFilter,
  generateEqualsSQLFilter,
  generateIsEmptySQLFilter,
  generateNotEmptySQLFilter,
  generateNotEqualsSQLFilter,
  sqlFiltersHelper,
} from '../../helpers/sqlFiltersHelper';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService, populateArray } from '../sqlDB.service';

const enum ColumnNamesEnum {
  SERIAL_NUMBER = 'serialNumber',
  ASSET_TYPE_LABEL = 'assetTypeLabel',
  RELATED_ORGANIZATION_IDS = 'relatedOrganizationIds',
  ASSET_TYPE_ID = 'assetTypeId',
  PARENT_ID = 'parentId',
  ANCESTOR_IDS = 'ancestorIds',
  RELATED_CONTACT_IDS = 'relatedContactIds',
  TENANT_OWNER_CONTACT_ID = 'tenantOwnerContactId',
  CITY = 'city',
  COUNTRY = 'country',
  COUNTRY_PROVINCE = 'countryProvince',
  ZIP_POST_CODE = 'zipPostCode',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  STATUS = 'status',
  RELATED_QR_CODE_IDS = 'relatedQRCodeIds',
}

const PARAMS: SQLQueryParams<ColumnNamesEnum, keyof AssetOfflinePopulates, keyof AssetRawOffline> =
  {
    idString: '_id',
    tableName: SyncDataTypesEnum.ASSETS2,
    columns: {
      [ColumnNamesEnum.SERIAL_NUMBER]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.serialNumber?.trim(),
        sortNoCase: true,
        isSearchColumn: true,
      },
      [ColumnNamesEnum.ASSET_TYPE_LABEL]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.assetTypeLabel?.trim(),
        sortNoCase: true,
        isSearchColumn: true,
      },
      [ColumnNamesEnum.RELATED_ORGANIZATION_IDS]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.relatedOrganizationIds?.join(','),
        sortNoCase: true,
      },
      [ColumnNamesEnum.ASSET_TYPE_ID]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.assetTypeId,
      },
      [ColumnNamesEnum.PARENT_ID]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.parentId,
      },
      [ColumnNamesEnum.ANCESTOR_IDS]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.ancestorIds?.join(','),
      },
      [ColumnNamesEnum.RELATED_CONTACT_IDS]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.relatedContactIds?.join(','),
      },
      [ColumnNamesEnum.TENANT_OWNER_CONTACT_ID]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.tenantOwnerContactId,
      },
      [ColumnNamesEnum.CITY]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.location?.city?.trim(),
      },
      [ColumnNamesEnum.COUNTRY]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.location?.country?.trim(),
      },
      [ColumnNamesEnum.COUNTRY_PROVINCE]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.location?.countryProvince?.trim(),
      },
      [ColumnNamesEnum.ZIP_POST_CODE]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.location?.zipPostCode?.trim(),
      },
      [ColumnNamesEnum.CREATED_AT]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) =>
          val?.createdAt ? String(val.createdAt) : undefined,
      },
      [ColumnNamesEnum.UPDATED_AT]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) =>
          val?.updatedAt ? String(val.updatedAt) : undefined,
      },
      [ColumnNamesEnum.STATUS]: {
        type: 'TEXT',
        valueFunction: (val: AssetRawOffline) => val?.status,
      },
      [ColumnNamesEnum.RELATED_QR_CODE_IDS]: {
        type: 'INTEGER',
        valueFunction: (val: AssetRawOffline) => ((val?.relatedQRCodeIds?.length ?? 0 > 0) ? 1 : 0),
      },
    },
    populates: {
      relatedContacts: {
        targetKey: 'relatedContacts',
        populateFunction: async (val: AssetRawOffline, logger: LogService) =>
          await populateArray(SyncDataTypesEnum.CONTACTS, val?.relatedContactIds, true, logger),
      },
      relatedOrganizations: {
        targetKey: 'relatedOrganizations',
        populateFunction: async (val: AssetRawOffline, logger: LogService) =>
          await populateArray(
            SyncDataTypesEnum.ORGANIZATIONS,
            val?.relatedOrganizationIds,
            true,
            logger,
          ),
      },
    },
  };

const ASSET_FILTER_ENUM_TO_COLUMN_NAME: Record<AssetsOfflineAdvancedFilterColumns, string> = {
  [AssetsFilterEnum.CITY]: ColumnNamesEnum.CITY,
  [AssetsFilterEnum.RELATED_CONTACT]: ColumnNamesEnum.RELATED_CONTACT_IDS,
  [AssetsFilterEnum.COUNTRY]: ColumnNamesEnum.COUNTRY,
  [AssetsFilterEnum.COUNTRY_PROVINCE]: ColumnNamesEnum.COUNTRY_PROVINCE,
  [AssetsFilterEnum.CREATED]: ColumnNamesEnum.CREATED_AT,
  [AssetsFilterEnum.RELATED_ORGANIZATION]: ColumnNamesEnum.RELATED_ORGANIZATION_IDS,
  [AssetsFilterEnum.PARENT_ASSET]: ColumnNamesEnum.PARENT_ID,
  [AssetsFilterEnum.ANCESTOR_ASSET]: ColumnNamesEnum.ANCESTOR_IDS,
  [AssetsFilterEnum.ASSET_TYPE]: ColumnNamesEnum.ASSET_TYPE_ID,
  [AssetsFilterEnum.QR_CODE]: ColumnNamesEnum.RELATED_QR_CODE_IDS,
  [AssetsFilterEnum.TENANT_OWNER_CONTACT]: ColumnNamesEnum.TENANT_OWNER_CONTACT_ID,
  [AssetsFilterEnum.STATUS]: ColumnNamesEnum.STATUS,
  [AssetsFilterEnum.UPDATED]: ColumnNamesEnum.UPDATED_AT,
  [AssetsFilterEnum.ZIP_POST_CODE]: ColumnNamesEnum.ZIP_POST_CODE,
};

@Injectable()
export class AssetsNewOfflineService
  extends BaseOfflineService<AssetRawOffline, AssetsOfflineAdvancedFilterConfigKeys>
  implements AssetsNewOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, PARAMS, logger, store);
  }

  public async findOne(id: string, populate?: OfflinePopulateType): Promise<Asset> {
    const assetRaw = await this.getInstance(id, populate);
    return mapOneAssetRawOfflineToAsset(assetRaw);
  }

  public async findMany({
    limit,
    offset,
    sortColumn,
    sortDirection,
    searchValue,
    filterQuery,
    populate,
  }: AssetsFindManyOfflineQueryInterface): Promise<AssetsFindManyResponse> {
    const assetsRaw = await this.getManyItemsWithCount({
      limit,
      offset,
      sortColumn,
      sortDirection,
      searchValue,
      filterQuery,
      populate,
    });

    return {
      assets: assetsRaw.data.map(mapOneAssetRawOfflineToAsset),
      count: getNumberCount(assetsRaw.count ?? 0),
    };
  }

  public async findManyByIds(ids: string[], populate?: OfflinePopulateType): Promise<Asset[]> {
    if (!ids.length) {
      return [];
    }

    const filterString = `${PARAMS.tableName}._id IN (${ids.map((id) => `'${id}'`).join(',')})`;
    const assets = await this.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      filterString,
      populate,
    );

    return assets.map(mapOneAssetRawOfflineToAsset);
  }

  public override getAdvancedFilterString(
    filter: AdvancedFilter<AssetsOfflineAdvancedFilterConfigKeys>,
  ): string {
    const tableName = PARAMS.tableName;

    switch (filter.identifier) {
      case AssetsFilterEnum.CITY:
      case AssetsFilterEnum.COUNTRY:
      case AssetsFilterEnum.COUNTRY_PROVINCE:
      case AssetsFilterEnum.CREATED:
      case AssetsFilterEnum.UPDATED:
      case AssetsFilterEnum.ASSET_TYPE:
      case AssetsFilterEnum.STATUS:
      case AssetsFilterEnum.ZIP_POST_CODE:
      case AssetsFilterEnum.PARENT_ASSET:
      case AssetsFilterEnum.TENANT_OWNER_CONTACT: {
        const columnName = ASSET_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
        return sqlFiltersHelper(filter, `${tableName}.${columnName}`);
      }
      case AssetsFilterEnum.ANCESTOR_ASSET:
      case AssetsFilterEnum.RELATED_ORGANIZATION:
      case AssetsFilterEnum.RELATED_CONTACT: {
        const columnName = ASSET_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
        return generateContainsSQLFilter(`${tableName}.${columnName}`, filter.value);
      }
      case AssetsFilterEnum.QR_CODE: {
        const columnName = ASSET_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
        return generateBooleanSQLFilterItemValue(filter, `${tableName}.${columnName}`);
      }
      case AssetsFilterEnum.INVOLVED_CONTACT: {
        switch (filter.operator) {
          case AdvancedFilterOperatorEnum.IS:
            assertDefined(filter.value, 'filter.value should be defined for filter operator is');
            return concatSQLFiltersByOperator(
              [
                `${generateEqualsSQLFilter(`${tableName}.${ColumnNamesEnum.TENANT_OWNER_CONTACT_ID}`, filter.value)}`,
                `${generateContainsSQLFilter(`${tableName}.${ColumnNamesEnum.RELATED_CONTACT_IDS}`, filter.value)}`,
              ],
              SQLConcatOperator.OR,
            );
          case AdvancedFilterOperatorEnum.IS_NOT:
            assertDefined(
              filter.value,
              'filter.value should be defined for filter operator is not',
            );
            return concatSQLFiltersByOperator(
              [
                `${generateNotEqualsSQLFilter(`${tableName}.${ColumnNamesEnum.TENANT_OWNER_CONTACT_ID}`, filter.value)}`,
                `${generateDoesNotContainSQLFilter(`${tableName}.${ColumnNamesEnum.RELATED_CONTACT_IDS}`, filter.value)}`,
              ],
              SQLConcatOperator.AND,
            );
          case AdvancedFilterOperatorEnum.IS_EMPTY:
            return concatSQLFiltersByOperator(
              [
                `${generateIsEmptySQLFilter(`${tableName}.${ColumnNamesEnum.TENANT_OWNER_CONTACT_ID}`)}`,
                `${generateIsEmptySQLFilter(`${tableName}.${ColumnNamesEnum.RELATED_CONTACT_IDS}`)}`,
              ],
              SQLConcatOperator.AND,
            );
          case AdvancedFilterOperatorEnum.IS_NOT_EMPTY:
            return concatSQLFiltersByOperator(
              [
                `${generateNotEmptySQLFilter(`${tableName}.${ColumnNamesEnum.TENANT_OWNER_CONTACT_ID}`)}`,
                `${generateNotEmptySQLFilter(`${tableName}.${ColumnNamesEnum.RELATED_CONTACT_IDS}`)}`,
              ],
              SQLConcatOperator.OR,
            );
          default:
            throw new Error(
              `Operator ${filter.operator} can't be used for ${AssetsFilterEnum.INVOLVED_CONTACT} filter type`,
            );
        }
      }
      default:
        throw new Error("Field can't be used as a filter identifier");
    }
  }
}
