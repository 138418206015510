<div class="rb-dialog-header-container">
  <div class="rb-dialog-title-container">
    <h2 class="rb-dialog-title-header">
      <span i18n="@@editX">Edit {{ data.label }}</span>
    </h2>
  </div>
</div>

<mat-dialog-content class="rb-content-container" [formGroup]="form">
  <div class="flex-col align-items-start full-width">
    <p>
      <ng-container i18n="@@currentLanguageColon">Current language:</ng-container>
      {{ data.currentLanguage | translateIsoLanguageCodesEnum }}
    </p>
    <mat-form-field
      appearance="outline"
      class="my-mat-form-field my-mat-form-field-outline full-width"
    >
      <mat-label>{{ data.currentLanguage | translateIsoLanguageCodesEnum }}</mat-label>
      <input
        matInput
        type="text"
        data-test-id="current-language-input"
        [formControl]="form.controls[data.currentLanguage]"
      />
    </mat-form-field>

    <p i18n="@@otherTranslationsColon">Other translations:</p>
    <div class="flex-row flex-gap-10 full-width" *ngFor="let lang of filteredLanguages">
      <img class="img-flag" [src]="MAP_ISO_LANGUAGE_TO_FLAG_SVG[lang]" />
      <mat-form-field
        appearance="outline"
        class="my-mat-form-field my-mat-form-field-outline full-width"
      >
        <mat-label>{{ lang | translateIsoLanguageCodesEnum }}</mat-label>
        <input
          matInput
          type="text"
          [formControl]="form.controls[lang]"
          [attr.data-test-id]="'other-lang-input-' + lang"
        />
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<div class="rb-dialog-footer-container">
  <button mat-stroked-button data-test-id="cancel" i18n="@@cancel" mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    data-test-id="confirm-button"
    [disabled]="form.invalid || form.pristine"
    (click)="handleConfirmClick()"
  >
    <span i18n="@@save">Save</span>
  </button>
</div>
