import { Data, Route, Routes } from '@angular/router';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import {
  PermissionAction,
  canMatchFeatureFlag,
  canMatchOneOfPermissions,
  canMatchPermission,
} from '@remberg/ui-core/core';
import { FeatureDisabledComponent } from '../ui/feature-disabled/feature-disabled.component';
import { FeatureRestrictedComponent } from '../ui/feature-restricted/feature-restricted.component';

export interface RouteAccessConfig {
  path?: string;
  targetRoute: Omit<Route, 'path'>;
  featureFlag?: FeatureFlagEnum;
  permissions?: PermissionAction[];
  featureDisabledRouteData?: Data;
}

/**
 * Based on passed feature flag and/or permissions(s), returns a set of routes that are matched conditionally
 * to return:
 * - FeatureDisabledComponent - if feature flag is configured but disabled
 * - FeatureRestrictedComponent - if permissions are configured but user doesn't have access to one of them
 * - target component
 *     - if feature flag is enabled (if configured)
 *     - if user has access to a single permission (if configured)
 *     - if user has access to all of permissions (if configured)
 *
 * From resulting config, only 1 route will be matched at a time.
 */
export function generateChildrenMatchedByAccess({
  path = '',
  targetRoute,
  featureFlag,
  permissions,
  featureDisabledRouteData,
}: RouteAccessConfig): Routes {
  const routes: Routes = [];

  const routeTarget: Route = {
    ...targetRoute,
    path,
    canMatch: targetRoute.canMatch ?? [],
  };

  if (featureFlag) {
    const routeFeatureDisabled: Route = {
      path,
      component: FeatureDisabledComponent,
      canMatch: [canMatchFeatureFlag(featureFlag, false)],
    };
    routeTarget.canMatch?.push(canMatchFeatureFlag(featureFlag, true));

    if (featureDisabledRouteData) {
      routeFeatureDisabled.data = featureDisabledRouteData;
    }

    routes.push(routeFeatureDisabled);
  }

  if (permissions?.length) {
    const routeFeatureRestricted: Route = {
      path,
      component: FeatureRestrictedComponent,
      canMatch: [],
    };

    if (featureFlag) {
      routeFeatureRestricted.canMatch?.push(canMatchFeatureFlag(featureFlag, true));
    }

    for (const permission of permissions) {
      routeTarget.canMatch?.push(canMatchPermission(permission, true)); // AND - if all of them are satisfied
    }
    routeFeatureRestricted.canMatch?.push(canMatchOneOfPermissions(permissions, false)); // OR - if any of them is missing

    routes.push(routeFeatureRestricted);
  }

  routes.push(routeTarget);

  return routes;
}
