import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { Observable, map } from 'rxjs';
import { GlobalSelectors, RootGlobalState } from '../store';

const isEnabled = (flag: FeatureFlagEnum): Observable<boolean> => {
  const store: Store<RootGlobalState> = inject(Store);

  return store.select(GlobalSelectors.selectHasFeature(flag));
};

const getRootUrlTree = (): UrlTree => {
  const router = inject(Router);
  return router.createUrlTree(['/']);
};

export const canActivateFeatureFlagEnabled =
  (flag: FeatureFlagEnum, ifEnabled = true): CanActivateFn =>
  () =>
    isEnabled(flag).pipe(map((allowed) => (ifEnabled ? allowed : !allowed || getRootUrlTree())));

export const canMatchFeatureFlag =
  (flag: FeatureFlagEnum, ifEnabled = true): CanMatchFn =>
  () =>
    isEnabled(flag).pipe(map((allowed) => (ifEnabled ? allowed : !allowed)));
