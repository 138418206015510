import { FuzzyCount } from '@remberg/global/common/core';

export enum OnlineStatusDataTypeEnum {
  /** Data is the same as online. */
  ONLINE = 'online',
  /** Data was changed after going offline. But, it is already present on the server. */
  OFFLINE_CHANGE = 'offlineChange',
  /** Data was created after going offline. */
  OFFLINE_CREATION = 'offlineCreation',
}

export class DataResponse<T> {
  data: T;
  onlineStatus?: OnlineStatusDataTypeEnum;
  draftId?: number;

  constructor(data: T, onlineStatus?: OnlineStatusDataTypeEnum) {
    this.data = data;
    this.onlineStatus = onlineStatus;
  }
}

export enum HTTP_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
}

export class ApiResponse<T> {
  data: T;
  count?: FuzzyCount;
  requestHandlerId?: string;
  requestId?: string;
  status?: HTTP_STATUS;

  constructor(data: T, count?: FuzzyCount) {
    this.data = data;
    this.count = count;
  }
}
