import { ProductType } from '@remberg/assets/common/base';
import {
  CustomPropertyTypesEnum,
  CustomPropertyValue,
} from '@remberg/custom-properties/common/main';
import { Asset } from '../../assets';
import {
  AssetCustomPropertyTypesEnum,
  AssetCustomPropertyValueLegacy,
  Product,
} from '../definitions';
import { createMapper } from './create-mapper';

const ASSET_LEGACY_CUSTOM_PROPERTY_TYPE_TO_CUSTOM_PROPERTY_TYPE: Record<
  AssetCustomPropertyTypesEnum,
  CustomPropertyTypesEnum
> = {
  [AssetCustomPropertyTypesEnum.INPUT_TEXT]: CustomPropertyTypesEnum.INPUT_TEXT,
  [AssetCustomPropertyTypesEnum.TEXTAREA]: CustomPropertyTypesEnum.TEXTAREA,
  [AssetCustomPropertyTypesEnum.CHECKBOX]: CustomPropertyTypesEnum.CHECKBOX,
  [AssetCustomPropertyTypesEnum.INPUT_NUMBER]: CustomPropertyTypesEnum.INPUT_NUMBER,
  [AssetCustomPropertyTypesEnum.URL]: CustomPropertyTypesEnum.URL,
  [AssetCustomPropertyTypesEnum.SELECT]: CustomPropertyTypesEnum.SELECT,
  [AssetCustomPropertyTypesEnum.REMBERG_ASSET_SELECT]: CustomPropertyTypesEnum.REMBERG_ASSET_SELECT,
  [AssetCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT]:
    CustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT,
  [AssetCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT]:
    CustomPropertyTypesEnum.REMBERG_CONTACT_SELECT,
  [AssetCustomPropertyTypesEnum.REMBERG_DATE]: CustomPropertyTypesEnum.REMBERG_DATE,
  [AssetCustomPropertyTypesEnum.REMBERG_DATETIME]: CustomPropertyTypesEnum.REMBERG_DATETIME,
};

const mapLegacyValueToNewValue = (
  customPropertyValue: AssetCustomPropertyValueLegacy, // always populated
): any => {
  switch (customPropertyValue.property.fieldType) {
    case AssetCustomPropertyTypesEnum.REMBERG_ASSET_SELECT:
    case AssetCustomPropertyTypesEnum.REMBERG_ORGANIZATION_SELECT:
    case AssetCustomPropertyTypesEnum.REMBERG_CONTACT_SELECT:
      return customPropertyValue.value?._id ? [customPropertyValue.value?._id] : [];
    default:
      return customPropertyValue.value;
  }
};

function mapLegacyCustomPropertyToCustomProperty(
  customPropertyValues?: AssetCustomPropertyValueLegacy[],
): CustomPropertyValue<false>[] {
  if (!customPropertyValues) return [];

  return customPropertyValues.map((customPropValue) => ({
    customPropertyId: getStringID(customPropValue.property),
    externalReference: customPropValue.propertyId?.toString(),
    propertyType:
      ASSET_LEGACY_CUSTOM_PROPERTY_TYPE_TO_CUSTOM_PROPERTY_TYPE[customPropValue.property.fieldType],
    value: mapLegacyValueToNewValue(customPropValue),
    valuePopulated: undefined,
  }));
}

function hierarchyPathToAncestorIds(hierarchyPath: string | undefined): string[] {
  return (hierarchyPath ?? '').split(',').filter(Boolean);
}

function hierarchyPathToParentId(hierarchyPath: string | undefined): string | undefined {
  return hierarchyPathToAncestorIds(hierarchyPath)[0];
}

function productToAsset(product: Product): Asset;
function productToAsset(product: Product | undefined): Asset | undefined {
  if (!product) {
    return undefined;
  }

  return {
    _id: product._id,
    serialNumber: product.serialNumber,
    relatedOrganizationIds: product.customerOrganization
      ? [getStringID(product.customerOrganization)]
      : [],
    tenantId: product.tenantId!,
    relatedContactIds: product.customerContact ? [getStringID(product.customerContact)] : [],
    tenantOwnerContactId: product.manufacturerContact
      ? getStringID(product.manufacturerContact)
      : undefined,
    assetTypeId: getStringID(product.productType),
    assetTypeLabel: product.productTypeName,
    assetTypeImageId: (product.productType as ProductType)?.image,
    relatedQRCodeIds: product.qrcode ? [getStringID(product.qrcode)] : [],
    location: product.location,
    userGroupIds: product.userGroups?.map(getStringID) ?? [],
    updatedAt: product.lastModified!,
    createdAt: product.createdAt!,
    status: product.status!,
    parentId: hierarchyPathToParentId(product.assetHierarchy),
    ancestorIds: hierarchyPathToAncestorIds(product.assetHierarchy),
    createdById: getStringID(product.createContext?.source ?? ''),
    customPropertyValues: mapLegacyCustomPropertyToCustomProperty(product.customProperties),
    updatedById: undefined,
    parent: undefined,
    relatedOrganizations: undefined,
    tenantOwnerContact: undefined,
    relatedContacts: undefined,
    createdBy: undefined,
  };
}

export const mapToAsset = createMapper(productToAsset);

function getStringID(obj: string | { _id?: string; id?: string }): string {
  if (typeof obj === 'string') {
    return obj; // Return obj if it's already a string
  }

  // Return _id if available, otherwise return id
  return obj._id || obj.id || '';
}
