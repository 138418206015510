import sanitizeHTML from 'sanitize-html';

export const anyCharacterRegex: RegExp = /^.*$/;

export const anyNonNegativeCharacterValueRegex: RegExp = /^[^-].*$/;

export const REMBERG_SIGNATURE_TAG = 'data-remberg-signature';
export const REMBERG_FIGURE_TAG = 'data-remberg-figure';

const mentionDataAttributes = [
  {
    name: 'data-denotation-char',
    multiple: false,
    values: ['@'],
  },
  'data-id',
  'data-user-id',
  'data-mention',
  'data-value',
];

export const sanitizeHTMLOptions: sanitizeHTML.IOptions = {
  allowedTags: sanitizeHTML.defaults.allowedTags.concat(['img', 'strike', 'font']),
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    '*': ['style', REMBERG_SIGNATURE_TAG, REMBERG_FIGURE_TAG],
    td: ['colspan', 'rowspan', 'width', 'height'],
    a: [
      'href',
      'name',
      {
        name: 'target',
        multiple: false,
        values: ['_blank'],
      },
      {
        name: 'rel',
        multiple: true,
        values: ['noopener', 'noreferrer'],
      },
      ...mentionDataAttributes,
    ],
    // We don't currently allow img itself by default, but this
    // would make sense if we did. You could add srcset here,
    // and if you do the URL is checked for safety
    img: ['src', 'alt', 'width', 'height'],
    span: [...mentionDataAttributes],
    font: ['color'],
  },
  // Lots of these won't come up by default because we don't allow them
  selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
  // URL schemes we permit
  allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
  allowedSchemesByTag: {
    img: ['http', 'https', 'cid'],
  },
  nonTextTags: ['style', 'script', 'textarea', 'option', 'noscript', 'head', 'o:p'],
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
  allowedStyles: {
    '*': {
      color: [anyCharacterRegex],
      'aspect-ratio': [anyCharacterRegex],
      'background-color': [anyCharacterRegex],
      background: [anyCharacterRegex],
      'text-align': [anyCharacterRegex],
      'font-size': [anyCharacterRegex],
      'text-indent': [anyNonNegativeCharacterValueRegex],
      'font-family': [anyCharacterRegex],
      'font-weight': [anyCharacterRegex],
      margin: [anyCharacterRegex],
      'vertical-align': [anyCharacterRegex],
      'margin-left': [anyCharacterRegex],
      'margin-top': [anyCharacterRegex],
      'margin-right': [anyCharacterRegex],
      'margin-bottom': [anyCharacterRegex],
      'white-space': [anyCharacterRegex],
      display: [anyCharacterRegex],
      'text-decoration-line': [anyCharacterRegex],
      'text-decoration': [anyCharacterRegex],
      'text-decoration-skip-ink': [anyCharacterRegex],
      border: [anyCharacterRegex],
      'border-left': [anyCharacterRegex],
      'border-top': [anyCharacterRegex],
      'border-bottom': [anyCharacterRegex],
      'border-right': [anyCharacterRegex],
      'border-style': [anyCharacterRegex],
      'border-color': [anyCharacterRegex],
      'border-width': [anyCharacterRegex],
      'border-radius': [anyCharacterRegex],
      'line-height': [anyCharacterRegex],
      height: [anyCharacterRegex],
      width: [anyCharacterRegex],
      overflow: [anyCharacterRegex],
      padding: [anyCharacterRegex],
      'padding-inline-start': [anyCharacterRegex],
      'border-collapse': [anyCharacterRegex],
      'overflow-wrap': [anyCharacterRegex],
      float: [anyCharacterRegex],
    },
    ul: {
      'list-style-type': [anyCharacterRegex],
    },
    ol: {
      'list-style-type': [anyCharacterRegex],
    },
    li: {
      'list-style-type': [anyCharacterRegex],
    },
  },
  allowedClasses: {
    span: ['mention'],
    a: ['mention'],
    figure: [
      'image',
      'image_resized',
      'image-inline',
      'image-style-align-left',
      'image-style-align-center',
      'image-style-align-right',
      'image-style-block-align-left',
      'image-style-block-align-center',
      'image-style-block-align-right',
      'table',
      'table-align-left',
      'table-align-center',
      'table-align-right',
    ],
    div: ['quote-box'],
    p: ['quote-box-descriptor'],
  },
};

export const allAllowedAttributes = Object.values(sanitizeHTMLOptions.allowedAttributes ?? {})
  .flatMap((field) => field)
  .map((attr) => (typeof attr === 'string' ? attr : attr.name));
