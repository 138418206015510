import { Inject, Injectable } from '@angular/core';
import { Product } from '@remberg/assets/common/main';
import { ASSETS_OFFLINE_SERVICE, AssetsOfflineServiceInterface } from '@remberg/assets/ui/clients';
import { ContactBasic, OrganizationBasic } from '@remberg/crm/common/base';
import { StepsProvider } from '@remberg/forms/common/main';
import { Address } from '@remberg/global/ui';
import { PrefillingStepFunctions } from './interfaces';

@Injectable()
export class AssetPrefillingStepsProvider implements StepsProvider<PrefillingStepFunctions> {
  constructor(
    @Inject(ASSETS_OFFLINE_SERVICE)
    private readonly assetsService: AssetsOfflineServiceInterface,
  ) {}

  public getSteps(): Partial<PrefillingStepFunctions> {
    return {
      assetIdToAsset: (id: string | undefined) => this.loadAsset(id),
      assetToParentAssetId,
      assetToCustomerPersonUserId,
      assetToManufacturerPersonUserId,
      assetToAssetTypeNameString,
      assetToLocationAddress,
      assetToCustomerAccountId,
      assetToCustomPropertyValue,
    };
  }

  public async loadAsset(id: string | undefined): Promise<Product | undefined> {
    return id ? await this.assetsService.tryGetInstance({ id }) : undefined;
  }
}

// custom properties are not supported in offline mode yet
// they need to be synced and displayed first
// frontend/src/app/ionic/services/assets.offline.service.ts
function assetToCustomPropertyValue(
  asset: Product | undefined,
  propertyId: unknown | undefined,
): unknown | undefined {
  return undefined;
}

function assetToAssetTypeNameString(asset: Product | undefined): string | undefined {
  return asset?.productTypeName;
}

function assetToCustomerAccountId(asset: Product | undefined): string | undefined {
  const customer = asset?.customerOrganization;
  return isOrganization(customer) ? customer._id : customer;
}

function assetToLocationAddress(asset: Product | undefined): Address | undefined {
  return asset?.location;
}

function assetToParentAssetId(asset: Product | undefined): string | undefined {
  const assetHierarchyList: string[] | undefined = asset?.assetHierarchy
    ?.split(',')
    .filter((e) => !!e);
  return assetHierarchyList?.[assetHierarchyList?.length - 1] || undefined;
}

function assetToCustomerPersonUserId(asset: Product | undefined): string | undefined {
  const customerContact = asset?.customerContact;
  return isContact(customerContact) ? customerContact._id : customerContact;
}

function assetToManufacturerPersonUserId(asset: Product | undefined): string | undefined {
  const manufacturerContact = asset?.manufacturerContact;
  return isContact(manufacturerContact) ? manufacturerContact._id : manufacturerContact;
}

function isOrganization(
  organization: OrganizationBasic | string | undefined,
): organization is OrganizationBasic {
  return !!organization && !!(organization as OrganizationBasic)._id;
}

function isContact(user: ContactBasic | string | undefined): user is ContactBasic {
  return !!user && !!(user as ContactBasic)._id;
}
