import { keysOf } from '@remberg/global/common/core';
import { getDefinedOrThrow, isDefined } from '../core';
import {
  ConfigWithPrefilling,
  FormInstanceData,
  FormTemplateConfig,
  KnownPrefillingSteps,
  PrefillableTargetPropertyEnum,
  PrefillableTargetType,
  PrefillingConfig,
  PrefillingInputs,
  PrefillingRule,
  PrefillingStepName,
  PrefillingStepNameWithArguments,
} from '../models';
import {
  FormFieldSectionData,
  FormSection,
  FormSectionData,
  FormSectionTypesEnum,
} from '../models/form-sections';
import { getPrefillableDataPropertyName } from './helpers';
import { StepFactory, StepFunctions } from './interfaces';

export class FormInstancePrefillingService {
  public constructor(
    private readonly stepFactory: StepFactory<StepFunctions<KnownPrefillingSteps>>,
  ) {}

  public async prefill(
    config: FormTemplateConfig,
    data: FormInstanceData,
    inputs: PrefillingInputs,
  ): Promise<FormInstanceData> {
    const prefilledData: FormInstanceData = [];

    for (const [sectionIndex, section] of config.sections.entries()) {
      const sectionData = getDefinedOrThrow(data[sectionIndex]);

      const prefilledSectionData = await this.prefillSection(section, sectionData, inputs);

      prefilledData.push(prefilledSectionData);
    }

    return prefilledData;
  }

  private async prefillSection(
    section: FormSection,
    sectionData: FormSectionData,
    inputs: PrefillingInputs,
  ): Promise<FormSectionData> {
    const { type, config } = section;

    if (type === FormSectionTypesEnum.FIELD_SECTION) {
      const prefilledFormFieldSectionData: FormFieldSectionData = { fields: [] };

      for (const [fieldIndex, field] of section.fields.entries()) {
        const fieldConfig = field.config;
        const fieldData = getDefinedOrThrow(
          (sectionData as FormFieldSectionData).fields[fieldIndex],
        );

        const prefilledFieldData = isConfigWithPrefilling(fieldConfig)
          ? await this.prefillData(
              fieldConfig.prefill,
              field.type as PrefillableTargetType,
              inputs,
              fieldData,
            )
          : fieldData;

        prefilledFormFieldSectionData.fields.push(prefilledFieldData);
      }

      return prefilledFormFieldSectionData;
    }

    if (isConfigWithPrefilling(config)) {
      return await this.prefillData(
        config.prefill,
        type as PrefillableTargetType,
        inputs,
        sectionData,
      );
    }

    return sectionData;
  }

  private async prefillData<T extends object>(
    config: PrefillingConfig,
    targetType: PrefillableTargetType,
    inputs: PrefillingInputs,
    data: T,
  ): Promise<T> {
    let state: T = data;

    for (const targetProperty of keysOf(config) as PrefillableTargetPropertyEnum[]) {
      const dataPropertyName = getPrefillableDataPropertyName(targetType, targetProperty);
      const rules: PrefillingRule[] = config[targetProperty] || [];

      for (const rule of rules) {
        const outputValue = await this.executeRule(rule, inputs);

        if (isDefined(outputValue)) {
          state = (
            dataPropertyName
              ? {
                  ...(state || {}),
                  [dataPropertyName]: outputValue,
                }
              : outputValue
          ) as T;

          break;
        }
      }
    }

    return state;
  }

  private async executeRule(rule: PrefillingRule, inputs: PrefillingInputs): Promise<unknown> {
    const inputValue = rule.input !== 'none' ? inputs[rule.input] : undefined;

    if (rule.input !== 'none' && !isDefined(inputValue)) {
      return undefined;
    }

    let tmpResult: unknown = inputValue;

    for (const stepConfig of rule.steps || []) {
      const [stepName, ...stepArgs] = toStepWithArguments(stepConfig);
      const stepFn = this.stepFactory.create(stepName);

      if (!stepFn) {
        return undefined;
      }

      try {
        tmpResult = await stepFn(tmpResult, ...stepArgs);
      } catch {
        return undefined;
      }

      if (!isDefined(tmpResult)) {
        return undefined;
      }
    }

    return tmpResult;
  }
}

function isConfigWithPrefilling(
  config: unknown | ConfigWithPrefilling,
): config is Required<ConfigWithPrefilling> {
  return !!(config as ConfigWithPrefilling)?.prefill;
}

function toStepWithArguments(
  config: PrefillingStepName | PrefillingStepNameWithArguments,
): PrefillingStepNameWithArguments {
  if (!Array.isArray(config)) {
    return [config] as PrefillingStepNameWithArguments;
  }

  return config;
}
