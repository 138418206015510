import { Inject, Injectable } from '@angular/core';
import { Product } from '@remberg/assets/common/main';
import { ContactBasic } from '@remberg/crm/common/base';
import {
  ContactCompatibility,
  OrganizationCompatibility,
  contactCompatibilityToContactBasic,
  contactRawToContactBasic,
} from '@remberg/crm/common/main';
import {
  CONTACTS_OFFLINE_SERVICE,
  ContactsOfflineServiceInterface,
  ORGANIZATIONS_OFFLINE_SERVICE,
  OrganizationOfflineServiceInterface,
} from '@remberg/crm/ui/clients';
import { PersonListItem, StepsProvider, Task } from '@remberg/forms/common/main';
import {
  RembergDate,
  TimeZoneEnum,
  formatToRembergNumberString,
} from '@remberg/global/common/core';
import { Address } from '@remberg/global/ui';
import { ServiceCase } from '@remberg/tickets/common/main';
import {
  SERVICE_CASE_OFFLINE_SERVICE,
  ServiceCaseOfflineServiceInterface,
} from '@remberg/tickets/ui/clients';
import { WorkOrder, WorkOrderTask } from '@remberg/work-orders-legacy/common/main';
import {
  WORK_ORDER_OFFLINE_SERVICE,
  WorkOrderOfflineServiceInterface,
} from '@remberg/work-orders-legacy/ui/clients';
import { toRembergDate } from '../../../datetime.service';
import { PrefillingStepFunctions } from './interfaces';

@Injectable()
export class WorkOrderPrefillingStepsProvider implements StepsProvider<PrefillingStepFunctions> {
  constructor(
    @Inject(WORK_ORDER_OFFLINE_SERVICE)
    private workOrderService: WorkOrderOfflineServiceInterface,
    @Inject(SERVICE_CASE_OFFLINE_SERVICE)
    private serviceCaseService: ServiceCaseOfflineServiceInterface,
    @Inject(CONTACTS_OFFLINE_SERVICE)
    private readonly contactService: ContactsOfflineServiceInterface,
    @Inject(ORGANIZATIONS_OFFLINE_SERVICE)
    private readonly organizationService: OrganizationOfflineServiceInterface,
  ) {}

  public getSteps(): Partial<PrefillingStepFunctions> {
    return {
      workOrderIdToWorkOrder: (id: string | undefined) => this.loadWorkOrder(id),
      workOrderToLocationAddress: (input: WorkOrder | undefined): Address | undefined =>
        input?.location,
      workOrderToTasks: (input: WorkOrder | undefined): Task[] | undefined =>
        input?.tasks?.map(toTask),
      workOrderToAssetIds: (input: WorkOrder | undefined): string[] | undefined =>
        input?.assets?.map((asset) =>
          isAsset(asset as Product) ? (asset as Product)._id : (asset as string),
        ),
      // second implementation
      workOrderToTitleString: (input: WorkOrder | undefined): string | undefined => input?.title,
      workOrderToDescriptionString: (input: WorkOrder | undefined): string | undefined =>
        input?.description,
      workOrderToDueDate: (input: WorkOrder | undefined) => input?.dueDate,
      workOrderToERPReferenceString: (input: WorkOrder | undefined): string | undefined =>
        input?.erpReference,
      workOrderToContacts: (input: WorkOrder | undefined): Promise<ContactBasic[]> | undefined => {
        if (!input?.contacts) {
          return undefined;
        }

        return Promise.all(
          input.contacts
            .map((contact) =>
              isContact(contact)
                ? contactCompatibilityToContactBasic(contact)
                : this.getContact(contact),
            )
            .filter(Boolean) as ContactBasic[],
        );
      },
      contactsToPersonListEntries: (
        input: ContactBasic[] | undefined,
      ): PersonListItem[] | undefined => (input ? input.map(toPersonListItem) : undefined),
      workOrderToResponsibleUserId: (input: WorkOrder | undefined): string | undefined =>
        isContact(input?.responsibleContact)
          ? input?.responsibleContact._id
          : input?.responsibleContact,
      workOrderToPerformByUserId: (input: WorkOrder | undefined): string | undefined =>
        isContact(input?.toPerformByContact)
          ? input?.toPerformByContact._id
          : input?.toPerformByContact,
      workOrderToTypeNumberString: (input: WorkOrder | undefined): string | undefined =>
        typeof input?.type?.orderValue === 'number' ? input.type.orderValue + '' : undefined,
      workOrderToPriorityString: (input: WorkOrder | undefined): string | undefined =>
        input?.priority,
      workOrderToStartDate: async (
        input: WorkOrder | undefined,
      ): Promise<RembergDate | undefined> => {
        const tz = await this.getOrganizationTimeZone(input?.organization);
        return input?.startDate ? toRembergDate(new Date(input.startDate), tz) : undefined;
      },
      workOrderToEndDate: async (
        input: WorkOrder | undefined,
      ): Promise<RembergDate | undefined> => {
        const tz = await this.getOrganizationTimeZone(input?.organization);
        return input?.endDate ? toRembergDate(new Date(input.endDate), tz) : undefined;
      },
      workOrderToAdditionalContactInformationString: (
        input: WorkOrder | undefined,
      ): string | undefined => input?.additionalContactInformation,
      workOrderToOrganizationAccountId: (input: WorkOrder | undefined): string | undefined =>
        isOrganization(input?.assignedOrganization)
          ? input?.assignedOrganization._id
          : input?.assignedOrganization,
      workOrderToCaseSubjectString: async (
        input: WorkOrder | undefined,
      ): Promise<string | undefined> => {
        const sCase = await this.getServiceCase(input?.relatedServiceCase);
        return sCase?.subject ?? undefined;
      },
      workOrderToCaseTicketAndSubjectString: async (
        input: WorkOrder | undefined,
      ): Promise<string | undefined> => {
        const sCase = await this.getServiceCase(input?.relatedServiceCase);
        if (typeof sCase?.ticketID === 'number' && sCase.subject) {
          return `[#${('000000' + sCase.ticketID).slice(-6)}] ${sCase.subject}`;
        } else {
          return undefined;
        }
      },
      workOrderToCustomPropertyValue,
      workOrderToWorkOrderCounter: (input: WorkOrder | undefined): string | undefined =>
        input?.workOrderID ? formatToRembergNumberString(input.workOrderID, true) : undefined,
    };
  }

  private async loadWorkOrder(id: string | undefined): Promise<WorkOrder | undefined> {
    return id ? await this.workOrderService.tryGetInstance(id) : undefined;
  }

  private async getServiceCase(
    sCase: ServiceCase | string | undefined,
  ): Promise<ServiceCase | undefined> {
    if (typeof sCase === 'string') {
      return this.serviceCaseService.tryGetInstance(sCase);
    } else if (sCase && (sCase as ServiceCase)._id) {
      return sCase;
    }
    return undefined;
  }

  private async getContact(id: string): Promise<ContactBasic | undefined> {
    return contactRawToContactBasic(await this.contactService.tryGetInstance(id));
  }

  private async getOrganizationTimeZone(
    organizationInput?: string | OrganizationCompatibility,
  ): Promise<TimeZoneEnum | undefined> {
    if (!organizationInput) {
      return;
    }
    if (isOrganization(organizationInput)) {
      return organizationInput.crmData.tz;
    }
    const organization = await this.organizationService.getInstance(organizationInput);
    return organization?.crmData.tz;
  }
}

function toTask(workOrderTask: WorkOrderTask): Task {
  return {
    title: workOrderTask.title,
    comment: workOrderTask.comment,
    done: workOrderTask.done,
    highPriority: workOrderTask.highPriority,
    necessary: workOrderTask.necessary,
  };
}

function toPersonListItem(contact: ContactBasic): PersonListItem {
  return {
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.primaryEmail,
  };
}

// custom properties are not supported in offline mode yet
// they need to be synced and displayed first
// frontend/src/app/ionic/services/workOrder.offline.service.ts
function workOrderToCustomPropertyValue(
  input: WorkOrder | undefined,
  propertyId: unknown | undefined,
): unknown | undefined {
  return undefined;
}

function isContact(
  contact: ContactCompatibility | string | undefined,
): contact is ContactCompatibility {
  return !!contact && !!(contact as ContactCompatibility)._id;
}

function isOrganization(
  organization: OrganizationCompatibility | string | undefined,
): organization is OrganizationCompatibility {
  return !!organization && !!(organization as OrganizationCompatibility)._id;
}

function isAsset(asset: Product | string | undefined): asset is Product {
  return !!(asset as Product)?.serialNumber;
}
