import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, firstValueFrom, map } from 'rxjs';
import { PermissionAction } from '../definitions';
import { GlobalSelectors, RootGlobalState } from '../store';

const isPermitted = (action: PermissionAction): Observable<boolean> => {
  const store: Store<RootGlobalState> = inject(Store);

  return store.select(GlobalSelectors.selectHasPermission(action));
};

export const canMatchPermission =
  (action: PermissionAction, ifPermitted = true): CanMatchFn =>
  () =>
    isPermitted(action).pipe(map((allowed) => (ifPermitted ? allowed : !allowed)));

export const canMatchOneOfPermissions =
  (actions: PermissionAction[] = [], ifPermitted = true): CanMatchFn =>
  async () => {
    const permissionChecks = actions.map(async (action) => {
      const isAllowed = await firstValueFrom(isPermitted(action));
      return ifPermitted ? isAllowed : !isAllowed;
    });
    const results = await Promise.all(permissionChecks);
    return results.some((allowed) => allowed);
  };
