import { CountryCodes, PHONE_NUMBER_REGEX } from '../constants';
import { PhoneNumber } from '../definitions';

export function isValidPhoneNumber({ countryPrefix, number }: PhoneNumber): boolean {
  if (countryPrefix && isValidPhoneNumberValue(number)) {
    return !!CountryCodes?.find((countryCode) => countryCode[2] === countryPrefix);
  }
  return false;
}

export function isValidPhoneNumberValue(number?: string): boolean {
  return !!number && PHONE_NUMBER_REGEX.test(number);
}
