const IMAGE_WITH_ALT_REGEX = /<img [^>]*alt="(.{24})"[^>]*>/gm;
export function extractImageIds(html: string | undefined): string[] {
  return [
    ...new Set(
      new Array(...(html?.matchAll(IMAGE_WITH_ALT_REGEX) ?? []))
        .map((i) => i[1])
        .filter((id): id is string => id !== undefined),
    ),
  ];
}
