import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import {
  FileFormatEnum,
  FileSourceTypeEnum,
  PlatformFile,
  PlatformFilesFindManyQuery,
  PlatformFilesFindManyResponse,
  PlatformFilesZipManyBody,
} from '@remberg/files/common/main';
import { sanitizeFileName } from '@remberg/global/common/core';
import {
  API_URL_PLACEHOLDER,
  AbortToken,
  ApiResponse,
  CONNECTIVITY_SERVICE,
  ConnectivityServiceInterface,
  DataResponse,
  LogService,
  OnlineStatusDataTypeEnum,
  PushStatus,
  filterEmptyProps,
  generateObjectId,
  getStringID,
  openConfirmationDialog,
  programmaticallyDownloadFile,
} from '@remberg/global/ui';
import { DropzoneFile } from 'dropzone';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, Observable, firstValueFrom, from, iif, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { FilesystemService } from '../files-system.service';
import {
  PLATFORM_FILES_OFFLINE_SERVICE,
  PlatformFilesOfflineServiceInterface,
} from './platform-files.offline.service.interface';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PlatformFilesService {
  private readonly filesUrl = `${API_URL_PLACEHOLDER}/files/v2`;

  constructor(
    private readonly http: HttpClient,
    private readonly matDialog: MatDialog,
    private readonly logger: LogService,
    @Inject(CONNECTIVITY_SERVICE)
    private readonly connectivityService: ConnectivityServiceInterface,
    @Inject(PLATFORM_FILES_OFFLINE_SERVICE)
    private readonly platformFilesOfflineService: PlatformFilesOfflineServiceInterface,
    private readonly filesystemService: FilesystemService,
    private readonly _toastr: ToastrService,
  ) {}
  /**
   * Downloads a file depending on the connectivity status :
   *  - If ONLINE : File is fetched from the server. Progress is tracked inside the fileInput that is passed as reference.
   *  - If OFFLINE : File is fetched from the localStorage if possible. Progress is not tracked.
   * @param  {PlatformFile|File} fileInput
   * @returns Promise
   */
  public async downloadFile({
    fileInput,
    format,
  }: {
    fileInput?: PlatformFile | File;
    format?: FileFormatEnum;
  }): Promise<void> {
    if (!fileInput) {
      return;
    }
    // if the file is locally stored, download it directly
    if (!(fileInput as PlatformFile)._id) {
      // local files are already a Blob
      this.downloadBlob({
        blob: fileInput as File,
        fileName: (fileInput as File).name,
        fileType: (fileInput as File).type,
      });
      // in offline mode, try to fetch file locally or otherwise show popup warning
    } else if (!this.connectivityService.getConnected()) {
      const file = fileInput as PlatformFile;
      let blob;
      try {
        blob = await this.loadFile({ fileId: file?._id });
      } catch (error) {
        file.downloadProgress = undefined;
        this.showFileNotAvailableOfflinePopup();
        return;
      }
      if (blob) {
        file.downloadProgress = undefined;
        this.downloadBlob({ blob, fileName: file.originalname, fileType: file.type });
      }
    } else {
      // just download the file in case of online mode
      const file = Object.assign({}, fileInput, { downloadProgress: 0 }) as PlatformFile;
      let params = new HttpParams();
      if (format) {
        params = params.set('size', format);
      }
      return new Promise((resolve) => {
        this.http
          .get(this.filesUrl + '/download/' + file._id, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params,
            reportProgress: true,
            observe: 'events',
            responseType: 'blob' as 'json',
          })
          .subscribe((event) => {
            if ((event as HttpProgressEvent).type === HttpEventType.DownloadProgress) {
              const progressEvent: HttpProgressEvent = event as HttpProgressEvent;
              file.downloadProgress = progressEvent.total
                ? Math.round((100 * progressEvent.loaded) / progressEvent.total)
                : undefined;
            } else if (event instanceof HttpResponse) {
              this.downloadBlob({
                blob: event.body as Blob,
                fileName: file.originalname,
                fileType: file.type,
              });
              file.downloadProgress = undefined;
              resolve();
            }
          });
      });
    }
  }

  /**
   * Works both in offline mode and in offline mode. Returns the the created file.
   * @param  {DropzoneFile|File} file File to create
   * @param  {FileSourceTypeEnum} sourceType
   * @param  {User} user
   * @param  {boolean=false} reportProgress
   * @param  {string} source? Used for Cases to link the file to a caseId instead of the default accountId set in the backend
   * @returns Observable
   */
  // TODO: Bilal: Refactor and remove all references to use the uploadPlatformFile method below
  public createFile(
    file: DropzoneFile | File,
    sourceType: FileSourceTypeEnum,
    user: { _id: string; account: string },
    reportProgress: boolean = false,
    source?: string,
  ): Observable<DataResponse<PlatformFile> | undefined> {
    this.logger.debug()('File object creation starting...');
    const newFile = {} as PlatformFile;
    // General Values
    newFile._id = generateObjectId();
    newFile.account = getStringID(user.account);
    newFile.creator = getStringID(user);
    newFile.sourceType = sourceType;
    newFile.createdAt = new Date().toISOString();
    newFile.lastModified = new Date().toISOString();
    newFile.private = false;

    // File Specific Values
    newFile.originalname = file.name;
    newFile.type = file.type;
    newFile.size = file.size;
    newFile.downloadCount = 0;

    const formData = new FormData();
    formData.append('data', JSON.stringify(newFile));
    formData.append('file', file, encodeURIComponent(file.name));
    if (source) {
      formData.append('source', source);
    }

    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Create file in online mode.');
      return this.http
        .post<ApiResponse<PlatformFile>>(this.filesUrl, formData, {
          reportProgress,
          observe: 'events',
        })
        .pipe(
          mergeMap(async (event: HttpEvent<any>) => {
            if (event.type === HttpEventType.UploadProgress) {
              newFile.downloadProgress = Math.round((100 * event.loaded) / (event.total ?? 0));
              return new DataResponse<PlatformFile>(newFile, OnlineStatusDataTypeEnum.ONLINE);
            } else if (event.type === HttpEventType.Response) {
              newFile.downloadProgress = undefined;
              if (this.connectivityService.offlineCapabilitiesEnabled()) {
                await this.platformFilesOfflineService.addInstance(event.body.data);
                await this.filesystemService.saveFileToFilesystem(file, getStringID(newFile));
              }
              return new DataResponse<PlatformFile>(
                event.body.data,
                OnlineStatusDataTypeEnum.ONLINE,
              );
            }
            return undefined;
          }),
        );
    } else {
      this.logger.debug()('Create file in offline mode.');
      return from(this.filesystemService.saveFileToFilesystem(file, getStringID(newFile))).pipe(
        mergeMap(async () => {
          await this.platformFilesOfflineService.addInstance(
            newFile,
            OnlineStatusDataTypeEnum.OFFLINE_CREATION,
          );
          return new DataResponse<PlatformFile>(newFile, OnlineStatusDataTypeEnum.OFFLINE_CREATION);
        }),
      );
    }
  }

  public uploadPlatformFile(
    file: File,
    rembergFile: Partial<PlatformFile>,
  ): Observable<HttpEvent<ApiResponse<PlatformFile>>> {
    if (this.connectivityService.getConnected()) {
      const formData = new FormData();
      formData.append('data', JSON.stringify(rembergFile));
      formData.append('file', file, encodeURIComponent(file.name));
      return this.http.post<ApiResponse<PlatformFile>>(this.filesUrl, formData, {
        reportProgress: true,
        observe: 'events',
      });
    } else {
      this.logger.debug()('Create file in offline mode.');
      return from(this.filesystemService.saveFileToFilesystem(file, getStringID(rembergFile))).pipe(
        mergeMap(async () => {
          await this.platformFilesOfflineService.addInstance(
            rembergFile as PlatformFile,
            OnlineStatusDataTypeEnum.OFFLINE_CREATION,
          );
          return new ApiResponse<PlatformFile>(rembergFile as PlatformFile);
        }),
        map(() => new HttpResponse<ApiResponse<PlatformFile>>({ status: 201 })),
        startWith({ type: HttpEventType.Sent } as HttpEvent<ApiResponse<PlatformFile>>),
        catchError(() => of(new HttpResponse<ApiResponse<PlatformFile>>())),
      );
    }
  }

  public async addFileOfflineCreation(file: PlatformFile): Promise<DataResponse<PlatformFile>> {
    this.logger.debug()('addFileOfflineCreation');
    this.logger.debug()(file);

    const blob = await this.filesystemService.readFileFromFilesystem(file);
    const theFile = new File([blob], file.originalname, { type: file.type });

    const formData = new FormData();
    formData.append('data', JSON.stringify(file));
    formData.append('file', theFile, encodeURIComponent(theFile.name));

    const res = await firstValueFrom(
      this.http.post<ApiResponse<PlatformFile>>(this.filesUrl, formData),
    );
    if (this.connectivityService.offlineCapabilitiesEnabled()) {
      await this.platformFilesOfflineService.updateInstance(res.data);
      // remove file from the file system because it is already uploaded to the server
      await this.filesystemService.removeFileFromFilesystem(getStringID(res.data));
    }

    return new DataResponse<PlatformFile>(res.data, OnlineStatusDataTypeEnum.ONLINE);
  }
  /**
   * Works only in online mode.
   * @param file
   * @param sourceType
   * @param id
   */
  public uploadFile(
    file: File,
    sourceType: FileSourceTypeEnum,
    id: string,
  ): Observable<PlatformFile> {
    // Send multipart/formdata request here
    const formData = new FormData();
    formData.append('sourceType', sourceType);
    formData.append('_id', id);
    formData.append('file', file, encodeURIComponent(file.name));
    return this.http
      .post<ApiResponse<PlatformFile>>(this.filesUrl, formData)
      .pipe(map((res) => res.data));
  }

  /**
   * Download a file and return its data as a blob. Works only in online mode.
   * @param file_id
   * @returns
   */
  public downloadFileToBlob(fileId: string): Observable<Blob> {
    if (!this.connectivityService.getConnected()) {
      return from(this.platformFilesOfflineService.downloadFileToBlobOffline(fileId));
    }
    const base_url = this.filesUrl + '/download/' + fileId;
    const link = this.http.get<Blob>(base_url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
      reportProgress: true,
      observe: 'events',
      responseType: 'blob' as 'json',
    });

    const fileData = link.pipe(
      mergeMap((event) =>
        iif(() => event instanceof HttpResponse, of(event ? (event as any).body : event), EMPTY),
      ),
    );
    return fileData;
  }

  /**
   * Works both in online and offline mode. Returns the Blob of the file that is stored on the server, or locally in offline mode
   * @param file_id
   */
  public async loadFile({ fileId }: { fileId: string }): Promise<Blob> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams(),
      responseType: 'blob' as 'json',
    };
    if (this.connectivityService.getConnected()) {
      this.logger.debug()('Load file in online mode.');
      // In online mode, get file from server.
      return firstValueFrom(
        this.http.get<Blob>(this.filesUrl + '/download/' + fileId, httpOptions),
      );
    } else {
      // In offline mode, get file from local (only possible with ionic).
      this.logger.debug()('Load file in offline mode.');
      const file = await this.platformFilesOfflineService.getInstance(fileId, undefined);
      return await this.filesystemService.readFileFromFilesystem(file);
    }
  }

  public findMany(params: PlatformFilesFindManyQuery): Observable<PlatformFilesFindManyResponse> {
    if (!this.connectivityService.getConnected()) {
      return this.platformFilesOfflineService.findMany(params);
    }

    const { sourceIds, sourceTypes, ...findManyParams } = params;
    const httpParams = new HttpParams({
      fromObject: {
        ...filterEmptyProps(findManyParams),
        ...(sourceIds?.length && { sourceIds: JSON.stringify(sourceIds) }),
        ...(sourceTypes?.length && { sourceTypes: JSON.stringify(sourceTypes) }),
      },
    });
    return this.http.get<PlatformFilesFindManyResponse>(`${this.filesUrl}`, {
      params: httpParams,
    });
  }

  public getFile(fileId: string): Observable<PlatformFile> {
    return this.http
      .get<ApiResponse<PlatformFile>>(`${this.filesUrl}/${fileId}`, httpOptions)
      .pipe(map((res) => res.data));
  }

  public getFiles(fileIds?: string[]): Observable<PlatformFile[]> {
    return this.http
      .put<ApiResponse<PlatformFile[]>>(this.filesUrl, { fileIds })
      .pipe(map((res) => res.data));
  }

  public getFileIds(sourceType: FileSourceTypeEnum): Observable<string[]> {
    const url = `${this.filesUrl}/ids/${sourceType}`;
    return this.http.get<ApiResponse<string[]>>(url, httpOptions).pipe(map((res) => res.data));
  }

  public updateFile(file: PlatformFile): Observable<PlatformFile> {
    const url = `${this.filesUrl}/${file._id}`;
    return this.http
      .put<ApiResponse<PlatformFile>>(url, file, httpOptions)
      .pipe(map((res) => res.data));
  }
  /**
   * Works both in online and offline mode. Returns the deleted file in online mode.
   * @param file
   */
  public deleteFile(file: PlatformFile | string): Observable<void> {
    const file_id = typeof file === 'string' ? file : file._id;
    if (this.connectivityService.getConnected()) {
      const url = `${this.filesUrl}/${file_id}`;
      return this.http.delete<void>(url, httpOptions);
    } else {
      this.filesystemService.removeFileFromFilesystem(file_id);
      this.platformFilesOfflineService.deleteInstance(file_id);
      return new Observable<void>();
    }
  }

  public asFile(file: unknown): PlatformFile {
    return file as PlatformFile;
  }

  /**
   * This function is using the @capacitor-community/file-opener and @capacitor/filesystem to preview
   * or store a file on the native container
   * @param blob file data
   * @param fileName filename including type extension
   * @param fileType the data MIME type
   * @param saveToDirectory the preferred place where the file should be stored (see @capacitor/filesystem Directory docs)
   * *CAUTION* additional user permissions can be required on Android for the shared Documents directory - they can be denied permanently
   * @param openWithDefaultDialog let the platform decide on how to open the file or (when false)
   * show a dialog to the user what apps are available for this filetype
   */
  public async openFileInNativeAppContainer(
    blob: Blob,
    fileName: string,
    fileType: string,
    saveToDirectory: Directory = Directory.Data,
    openWithDefaultDialog = true,
  ): Promise<void> {
    const reader = new FileReader();
    reader.onload = () => {
      Filesystem.writeFile({
        data: reader.result as string,
        path: sanitizeFileName(fileName),
        directory: saveToDirectory,
      })
        .then((res) => {
          if (fileType) {
            FileOpener.open({
              filePath: res?.uri,
              contentType: fileType,
              openWithDefault: openWithDefaultDialog,
            }).catch((e) => {
              this._toastr.error(
                $localize`:@@fileCouldNotBeOpenedDotIfTheIssuePersistsCommaPleaseContactRembergSupportDot:File could not be opened. If the issue persists, please contact remberg support.`,
              );
              this.logger.error()('Error opening file', e);
            });
          } else {
            this._toastr.error($localize`:@@fileNotCompatible:File not compatible`);
          }
        })
        .catch((e) => {
          this._toastr.error(
            $localize`:@@fileCouldNotBeOpenedDotIfTheIssuePersistsCommaPleaseContactRembergSupportDot:File could not be opened. If the issue persists, please contact remberg support.`,
          );
          this.logger.error()('Error writing file', e);
        });
    };
    reader.readAsDataURL(blob);
  }

  /**
   * This function downloads a blob object in a browser.
   * @param blob
   * @param fileName
   * @param fileType
   * @param useDefaultPreviewOnMobile let the platform decide on how to open the file or (when false)
   * show a dialog to the user what apps are available for this filetype
   */
  public async downloadBlob({
    blob,
    fileName,
    fileType,
    useDefaultPreviewOnMobile = false,
  }: {
    blob: Blob;
    fileName: string;
    fileType: string;
    useDefaultPreviewOnMobile?: boolean;
  }): Promise<void> {
    const isIonic = this.connectivityService.offlineCapabilitiesEnabled();
    if (isIonic) {
      this.openFileInNativeAppContainer(
        blob,
        fileName,
        fileType,
        Directory.Documents,
        useDefaultPreviewOnMobile,
      );
    } else {
      programmaticallyDownloadFile({ blob, targetFileName: fileName });
    }
  }

  public showFileNotAvailableOfflinePopup(): void {
    openConfirmationDialog(this.matDialog, {
      confirmButtonText: $localize`:@@confirm:Confirm`,
      contentText: $localize`:@@filesAreOnlyAvailableOnlineDotPleaseDownloadAnyFilesYouNeedBeforeGoingOffline:Files are only available online. Please download any files you need before you go offline.`,
      headerIcon: 'warning_amber',
    });
  }

  //#region Offline File Push

  public async pushOfflineFileCreations(
    statusSubject: BehaviorSubject<PushStatus | undefined>,
    abortToken: AbortToken,
  ): Promise<boolean> {
    // get push status
    const status = statusSubject.getValue() ?? {};
    status.formFiles = 0;

    const createdFiles = await this.platformFilesOfflineService.getInstances(
      undefined,
      undefined,
      undefined,
      undefined,
      `onlineStatus = '${OnlineStatusDataTypeEnum.OFFLINE_CREATION}'`,
    );
    for (const createdFile of createdFiles) {
      // before every create, we can abort:
      abortToken.check();
      statusSubject.next(status);
      try {
        const result: DataResponse<PlatformFile> = await this.addFileOfflineCreation(createdFile);
        this.logger.debug()('Pushed Create: ' + getStringID(createdFile));
        this.logger.debug()(result);
      } catch (error) {
        this.logger.error()('Error pushingOffline file creation: ', error);
      }
      // update push status
      status.formFiles += 1 / createdFiles.length;
    }

    // final status update
    status.formFiles = 1;
    statusSubject.next(status);

    return true;
  }

  public downloadFileWithSanitizedUrl(url: string, name: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(() => {
      window.URL.revokeObjectURL(link.href);
    }, 500);
  }

  public downloadFileUrlInBackground(fileurl: string, name: string): void {
    const a = document.createElement('a');
    a.href = fileurl;
    a.download = name;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public getZipFileSignedUrl(
    payload: PlatformFilesZipManyBody,
  ): Observable<{ signedUrl: string; name: string }> {
    return this.http
      .put(`${this.filesUrl}/zip`, payload, { responseType: 'text' })
      .pipe(map((signedUrl) => ({ signedUrl, name: payload.zipName })));
  }

  public downloadFileById({ pdfFileId }: { pdfFileId: string }): Observable<PlatformFile> {
    return this.getFile(pdfFileId).pipe(
      tap((pdfFile) => this.downloadFile({ fileInput: pdfFile })),
    );
  }
}
