import { Asset, AssetRaw } from '@remberg/assets/common/main';
import {
  ContactRaw,
  OrganizationRaw,
  contactRawToContactBasic,
  organizationRawToOrganizationBasic,
} from '@remberg/crm/common/main';

export interface AssetOfflinePopulates {
  relatedContacts: ContactRaw[];
  relatedOrganizations: OrganizationRaw[];
}

export type AssetRawOffline = AssetRaw & AssetOfflinePopulates;

export function mapOneAssetRawOfflineToAsset(asset: AssetRawOffline): Asset {
  return {
    _id: asset._id,
    tenantId: asset.tenantId,

    createdAt: asset.createdAt,
    updatedAt: asset.updatedAt,
    createdById: asset.createdById,
    updatedById: asset.updatedById,

    assetTypeId: asset.assetTypeId,
    assetTypeLabel: asset.assetTypeLabel,
    assetTypeImageId: asset.assetTypeImageId,
    parentId: asset.parentId,
    ancestorIds: asset.ancestorIds,
    serialNumber: asset.serialNumber,
    status: asset.status,
    relatedOrganizationIds: asset.relatedOrganizationIds,
    tenantOwnerContactId: asset.tenantOwnerContactId,
    relatedContactIds: asset.relatedContactIds,
    relatedQRCodeIds: asset.relatedQRCodeIds,
    location: asset.location,
    userGroupIds: asset.userGroupIds,
    customPropertyValues: asset.customPropertyValues,
    relatedContacts: asset.relatedContacts?.map((contactRaw) =>
      contactRawToContactBasic(contactRaw),
    ),
    relatedOrganizations: asset.relatedOrganizations?.map((organizationRaw) =>
      organizationRawToOrganizationBasic(organizationRaw),
    ),
  };
}
