import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedFilter, AdvancedFilterOperatorEnum } from '@remberg/advanced-filters/common/main';
import {
  AssetMapItem,
  AssetPublic,
  AssetsAdvancedFilterConfigKeys,
  AssetsCreateOneBody,
  AssetsFilterEnum,
  AssetsFindManyByIdsBody,
  AssetsFindManyForMapQuery,
  AssetsLegacyFilterFieldEnum,
  AssetsLegacySortFieldEnum,
  AssetsPopulateEnum,
  AssetsSortFieldsEnum,
  Product,
  mapAssetLegacyPublicToPublic,
  mapAssetToProduct,
} from '@remberg/assets/common/main';
import {
  AssetsLegacyAdvancedFilterConfigKeys,
  AssetsLegacyAdvancedFilterQuery,
  AssetsLegacyAdvancedStaticFilter,
  AssetsLegacyService,
} from '@remberg/assets/ui/clients';
import { isCustomPropertyKey } from '@remberg/custom-properties/common/main';
import { SortDirectionEnum } from '@remberg/global/common/core';
import { ApiResponse, DEFAULT_MAP_PAGESIZE } from '@remberg/global/ui';
import { Observable, catchError, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetsNewService } from './assets-new.service';

export const assetsLegacySortToAssetsSort: Record<AssetsLegacySortFieldEnum, AssetsSortFieldsEnum> =
  {
    [AssetsLegacySortFieldEnum.SERIAL_NUMBER]: AssetsSortFieldsEnum.SERIAL_NUMBER,
    [AssetsLegacySortFieldEnum.STATUS]: AssetsSortFieldsEnum.STATUS,
    [AssetsLegacySortFieldEnum.CREATED]: AssetsSortFieldsEnum.CREATED_AT,
    [AssetsLegacySortFieldEnum.UPDATED]: AssetsSortFieldsEnum.UPDATED_AT,
    [AssetsLegacySortFieldEnum.PRODUCT_TYPE]: AssetsSortFieldsEnum.ASSET_TYPE_LABEL,
    [AssetsLegacySortFieldEnum.COUNTRY]: AssetsSortFieldsEnum.COUNTRY,
    [AssetsLegacySortFieldEnum.COUNTRY_PROVINCE]: AssetsSortFieldsEnum.COUNTRY_PROVINCE,
    [AssetsLegacySortFieldEnum.CITY]: AssetsSortFieldsEnum.CITY,
    [AssetsLegacySortFieldEnum.ZIP_POST_CODE]: AssetsSortFieldsEnum.ZIP_POST_CODE,
    [AssetsLegacySortFieldEnum.STREET]: AssetsSortFieldsEnum.STREET,
  };

// AssetsLegacyFilterFieldEnum.ASSET_ID is not mapped as it's only used in old hierarchies and not implemented in new backend
export const assetsLegacyFilterToAssetsFilter: Record<
  AssetsLegacyFilterFieldEnum,
  AssetsFilterEnum
> = {
  [AssetsLegacyFilterFieldEnum.PRODUCT_TYPE]: AssetsFilterEnum.ASSET_TYPE,
  [AssetsLegacyFilterFieldEnum.CUSTOMER]: AssetsFilterEnum.RELATED_ORGANIZATION,
  [AssetsLegacyFilterFieldEnum.QR_CODE]: AssetsFilterEnum.QR_CODE,
  [AssetsLegacyFilterFieldEnum.ZIP_CODE]: AssetsFilterEnum.ZIP_POST_CODE,
  [AssetsLegacyFilterFieldEnum.CITY]: AssetsFilterEnum.CITY,
  [AssetsLegacyFilterFieldEnum.COUNTRY]: AssetsFilterEnum.COUNTRY,
  [AssetsLegacyFilterFieldEnum.COUNTRY_PROVINCE]: AssetsFilterEnum.COUNTRY_PROVINCE,
  [AssetsLegacyFilterFieldEnum.CONTACT_PERSON]: AssetsFilterEnum.RELATED_CONTACT,
  [AssetsLegacyFilterFieldEnum.INVOLVED_CONTACT]: AssetsFilterEnum.INVOLVED_CONTACT,
  [AssetsLegacyFilterFieldEnum.STATUS]: AssetsFilterEnum.STATUS,
  [AssetsLegacyFilterFieldEnum.PARENT_ASSET]: AssetsFilterEnum.ANCESTOR_ASSET,
  [AssetsLegacyFilterFieldEnum.CREATED]: AssetsFilterEnum.CREATED,
  [AssetsLegacyFilterFieldEnum.UPDATED]: AssetsFilterEnum.UPDATED,
  [AssetsLegacyFilterFieldEnum.ASSET_ID]: AssetsFilterEnum.PARENT_ASSET, // Only here to satisfy typescript, AssetsLegacyFilterFieldEnum.ASSET_ID is only used in old hierarchies and not implemented in new backend
  [AssetsLegacyFilterFieldEnum.PART_ID]: AssetsFilterEnum.RELATED_PART,
  [AssetsLegacyFilterFieldEnum.GROUP_ID]: AssetsFilterEnum.USER_GROUP,
  [AssetsLegacyFilterFieldEnum.BOUNDING_BOX]: AssetsFilterEnum.BOUNDING_BOX,
  [AssetsLegacyFilterFieldEnum.RESPONSIBLE]: AssetsFilterEnum.TENANT_OWNER_CONTACT,
};

export function mapLegacyPopulateToPopulateItems(
  populate?: boolean | Product,
): AssetsPopulateEnum[] {
  if (!populate) {
    return [];
  }

  if (typeof populate === 'boolean') {
    return Object.values(AssetsPopulateEnum);
  }

  const populateItems: AssetsPopulateEnum[] = [];

  const populateAsProduct = populate as Product;
  if (populateAsProduct.customerOrganization) {
    populateItems.push(AssetsPopulateEnum.RELATED_ORGANIZATIONS);
  }

  if (populateAsProduct.customerContact) {
    populateItems.push(AssetsPopulateEnum.RELATED_CONTACTS);
  }

  if (populateAsProduct.manufacturerContact) {
    populateItems.push(AssetsPopulateEnum.TENANT_OWNER_CONTACT);
  }

  if (populateAsProduct.createContext) {
    populateItems.push(AssetsPopulateEnum.CREATED_BY);
  }

  return populateItems;

  // TODO AssetsRework There is no need to populate these properties
  // if (populateAsProduct.productType) {}
  // if (populateAsProduct.qrcode) {}
}

export function mapLegacyFiltersToAssetsFilters(
  filters: AdvancedFilter<AssetsLegacyAdvancedFilterConfigKeys>[],
): AdvancedFilter<AssetsAdvancedFilterConfigKeys>[] {
  const advancedFilters: AdvancedFilter<AssetsAdvancedFilterConfigKeys>[] = [];
  for (const { identifier, operator, value } of filters) {
    if (isCustomPropertyKey(identifier)) {
      advancedFilters.push({ identifier, operator, value });
    } else {
      const newIdentifier =
        assetsLegacyFilterToAssetsFilter[identifier as AssetsLegacyFilterFieldEnum];
      advancedFilters.push({ identifier: newIdentifier, operator, value });
    }
  }

  return advancedFilters;
}

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(
    private readonly assetsLegacyService: AssetsLegacyService,
    private readonly assetsNewService: AssetsNewService,
  ) {}

  public findOne(
    isAssetsTemporaryEnabled: boolean,
    assetId: string,
    populate?: boolean,
    populateParent?: boolean,
  ): Observable<Product> {
    if (isAssetsTemporaryEnabled) {
      const populateItems: AssetsPopulateEnum[] = [];

      if (populate === true) {
        populateItems.push(
          AssetsPopulateEnum.RELATED_ORGANIZATIONS,
          AssetsPopulateEnum.TENANT_OWNER_CONTACT,
          AssetsPopulateEnum.RELATED_CONTACTS,
        );
      }

      if (populateParent) {
        populateItems.push(AssetsPopulateEnum.PARENT);
      }

      return this.assetsNewService
        .findOne(assetId, { populate: populateItems })
        .pipe(map((asset) => mapAssetToProduct(asset)));
    }

    return this.assetsLegacyService.getProduct(assetId, populate, populateParent);
  }

  public findMany(
    isAssetsTemporaryEnabled: boolean,
    {
      populate,
      pageSize,
      pageIndex,
      sortDirection,
      sortField,
      searchValue,
      filterQuery,
      staticFilters,
      ignoreUserGroups,
    }: {
      populate?: boolean | Product;
      pageSize?: number;
      pageIndex?: number;
      sortDirection?: SortDirectionEnum;
      sortField?: AssetsLegacySortFieldEnum;
      searchValue?: string;
      filterQuery?: AssetsLegacyAdvancedFilterQuery;
      staticFilters?: AssetsLegacyAdvancedStaticFilter[];
      ignoreUserGroups?: boolean;
    },
  ): Observable<ApiResponse<Product[]>> {
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewService
        .findMany({
          page: pageIndex ?? 0,
          limit: pageSize ?? 20,
          ignoreUserGroups,
          search: searchValue,
          sortDirection,
          sortField: sortField ? assetsLegacySortToAssetsSort[sortField] : undefined,
          filterObject: filterQuery
            ? {
                concatOperator: filterQuery.concatOperator,
                filters: mapLegacyFiltersToAssetsFilters(filterQuery.filters),
              }
            : undefined,
          staticFilters: staticFilters ? mapLegacyFiltersToAssetsFilters(staticFilters) : undefined,
          populate: mapLegacyPopulateToPopulateItems(populate),
        })
        .pipe(
          map(({ count, assets }) => ({
            count,
            data: assets.map((asset) => mapAssetToProduct(asset)),
          })),
        );
    }

    return this.assetsLegacyService.getProductsUsingAdvancedFilter(
      populate,
      pageSize,
      pageIndex,
      sortDirection,
      sortField,
      searchValue,
      filterQuery,
      staticFilters,
      ignoreUserGroups,
    );
  }

  public findManyForMap(
    isAssetsTemporaryEnabled: boolean,
    { boundingBox, filterObject, staticFilters, search }: AssetsFindManyForMapQuery,
  ): Observable<ApiResponse<AssetMapItem[]>> {
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewService
        .findManyForMap({
          boundingBox,
          filterObject,
          staticFilters,
          search,
        })
        .pipe(map(({ count, assets }) => ({ count, data: assets })));
    }

    const { minLongitude, minLatitude, maxLongitude, maxLatitude } = boundingBox;
    return this.assetsLegacyService
      .getProductsUsingAdvancedFilter(
        undefined,
        DEFAULT_MAP_PAGESIZE,
        0,
        undefined,
        undefined,
        search,
        undefined,
        [
          {
            identifier: AssetsLegacyFilterFieldEnum.BOUNDING_BOX,
            operator: AdvancedFilterOperatorEnum.IS,
            value: `${minLongitude},${minLatitude},${maxLongitude},${maxLatitude}`,
          },
        ],
      )
      .pipe(
        map(({ data, count }) => ({
          data: data.map(({ _id, location }) => ({
            _id,
            // BOUNDING_BOX static filter ensures us that the location latitude and longitude should be defined
            latitude: location?.latitude ?? 0,
            longitude: location?.longitude ?? 0,
          })),
          count,
        })),
      );
  }

  public findManyByIds(
    isAssetsTemporaryEnabled: boolean,
    { assetIds }: AssetsFindManyByIdsBody,
  ): Observable<Product[]> {
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewService
        .findManyByIds({ assetIds })
        .pipe(map((assets) => mapAssetToProduct(assets)));
    }
    return this.assetsLegacyService.getProductsByIds(assetIds);
  }

  public findOnePublic(assetId: string): Observable<AssetPublic> {
    return this.assetsLegacyService.getProductPublic(assetId).pipe(
      map((assetLegacyPublic) => mapAssetLegacyPublicToPublic(assetLegacyPublic)),
      catchError(() => this.assetsNewService.findOnePublic(assetId)),
    );
  }

  public createOne(
    isAssetsTemporaryEnabled: boolean,
    body: AssetsCreateOneBody & { manufacturerOrganizationId?: string },
  ): Observable<Product> {
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewService.createOne(body).pipe(map((asset) => mapAssetToProduct(asset)));
    }

    return this.assetsLegacyService.addProduct(body);
  }

  // TODO AssetsRework rename this method OR return type to reflect the actual nature of logic
  // true = exists, false = does not exist
  public checkSerialNumberUniqueness(
    isAssetsTemporaryEnabled: boolean,
    serialNumber: string,
    assetTypeId?: string,
  ): Observable<boolean> {
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewService.checkSerialNumberUniqueness({ serialNumber, assetTypeId }).pipe(
        map(() => false),
        catchError((error) => of(error.status === HttpStatusCode.Conflict)),
      );
    }

    return this.assetsLegacyService.serialNumberExists(serialNumber, assetTypeId);
  }

  public addUserGroup(
    isAssetsTemporaryEnabled: boolean,
    { assets, userGroupId }: { assets: Product[]; userGroupId: string },
  ): Observable<unknown> {
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewService.addUserGroup({
        assetIds: assets.map((asset) => asset._id),
        userGroupId,
      });
    }

    return forkJoin(
      assets.map(({ _id, userGroups }) =>
        this.assetsLegacyService.updateProduct({
          _id,
          userGroups: [...new Set([...(userGroups || []), userGroupId])],
        } as Product),
      ),
    );
  }

  public removeUserGroup(
    isAssetsTemporaryEnabled: boolean,
    { asset, userGroupId }: { asset: Product; userGroupId: string },
  ): Observable<unknown> {
    if (isAssetsTemporaryEnabled) {
      return this.assetsNewService.clearUserGroup({ assetIds: [asset._id], userGroupId });
    }

    const userGroups = (asset.userGroups as string[]).filter((groupId) => groupId !== userGroupId);
    return this.assetsLegacyService.updateProduct({ _id: asset._id, userGroups } as Product);
  }
}
