import { CustomPropertyModelEnum } from './enums';

export interface CustomPropertyModelConfig {
  isEnabled?: boolean;
  isPublic?: boolean;
  isExternalEditable?: boolean;
  isRequired?: boolean;
}

export const CUSTOM_PROPERTY_MODEL_PORTAL_ENABLED_CONFIG: Record<CustomPropertyModelEnum, boolean> =
  {
    [CustomPropertyModelEnum.WORK_ORDER]: false,
    [CustomPropertyModelEnum.ASSET]: true,
  };

export const CUSTOM_PROPERTY_MODEL_REQUIRED_ENABLED_CONFIG: Record<
  CustomPropertyModelEnum,
  boolean
> = {
  [CustomPropertyModelEnum.WORK_ORDER]: false,
  [CustomPropertyModelEnum.ASSET]: true,
};
